import React from 'react';
import {createUseStyles} from 'react-jss';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "../overrides.css";
import theme from '../theme';
import Navbar from '../Components/Navbar';
import HeroImage from './HeroImage'
import DrivingDigitalTransofmation from './DrivingDigitalTransofrmation';
import MissionVisionCoreValues from './MissionVisionCoreValues'
import Leadership from './Leadership';
import LeadershipCarousel from './LeadershipCarousel';
import InnovitesCarousel from './InnovitesCarousel'
import Footer from '../Home/Footer'
import OurJourney from './OurJourney'

const useStyles = createUseStyles({
    root: {

    },

    '@media screen and (max-width: 1024px)': {

    },

    '@media screen and (max-width: 600px)': {

    },
})

export default function AboutUs() {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Navbar/>
            <div style={{paddingTop:'55px'}}>
            <HeroImage/>
            <DrivingDigitalTransofmation/>
            <MissionVisionCoreValues/>
            {/* <OurJourney/> */}
            <Leadership/>
            {/* <LeadershipCarousel/> */}
            <InnovitesCarousel/>
            <Footer/>
            </div>
         
        </div>
    )
}
 