import React from 'react';
import {createUseStyles} from 'react-jss';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Divider from '../Components/Divider';
import "../overrides.css";
import theme from '../theme';



const useStyles = createUseStyles({
    root: {
        background:'#E2E2E2',
        paddingTop:'32px',
        paddingBottom:'24px'
    },
    productDevCarousel:{
        // margiTop:'100px',
        display:'grid',
        gridTemplateColumns:'1fr 1fr',
        maxWidth:'1024px',
        margin:'45px auto 0 auto',
        gridGap:'50px'

    },
    card:{
        fontSize:'.9rem',
        // lineHeight:'2rem',
        boxSizing:'border-box',
        margin:'16px',
        maxWidth:'480px',
        textAlign:'center',
        '& img':{
            width:'56px'
        },
        '& h3':{
            marginBottom:'16px'
        },
        '& p':{
            // textAlign:'justify'
        }
    },

    '@media screen and (max-width: 1024px)': {

    },

    '@media screen and (max-width: 600px)': {
        productDevCarousel:{
            gridTemplateColumns:'auto',
            maxWidth:'100%',
    
        },
    },
})

export default function WhatCanWeDo() {
    const classes = useStyles()

    const data = [
        {
            image:'/images/product_development/product_development.svg',
            heading:'Cloud Migration',
            description:'We help in moving your data to the cloud and secure path for you to enjoy speed, agility, flexibility and scale capacity.'
        },
        {
            image:'/images/product_development/web_development.svg',
            heading:'Azure, AWS Cloud Services',
            description:'Cloud Partner - migration, infrastructure design, implementation and support. We also support and manage cloud computing, AI/ML engine, Disaster Planning, Web Services etc.'
        },
        {
            image:'/images/product_development/front_end_development.svg',
            heading:'Continuous Integration',
            description:'We help to put CI practices into place, our developers has experience in using Azure, Jenkins, TravisCI, Hudson, Bamboo, CircleCI to name a few. Our CI experts will work with you to determine which CI tool will deliver the best results for your organization.'
        },
        {
            image:'/images/product_development/ui_ux_design.svg',
            heading:'Analysis of Data',
            description:'We pride ourselves on deriving truth and insight from any structured or unstructured data to identify and analyze the data Statistically and Graphically.'
        },
        {
            image:'/images/product_development/erp_software_development.svg',
            heading:'Continuous Delivery',
            description:'A key advantage of CD is immediate deployability. With an approach based on a streamlined, automated process, from building and testing to the release itself, our Continuous Delivery experts will help you reduce your deployment risk while enabling you to see deployments in production.'
        },
        {
            image:'/images/product_development/custom_software_development.svg',
            heading:'Automated Monitoring',
            description:"We help customers achieve fully automated monitoring interface from Creation of active metrics associated with data response times, CPU usage, load-balance alarms, exceptions etc. to establishing end-to-end application performance and stability, by identifying and leveraging preventative tasks on-demand and more."
        },
    ]

    return (
        <div className={classes.root}>
           <center>
               <h1>What can we do?</h1>
               <Divider marginTop={'32px'} marginTop={'32px'}/>

           </center>
           <div className={classes.productDevCarousel}>
               {
                   data.map((item,index)=>(
                    <div className={classes.card}>
                        <img src={item.image}/>
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                    </div>
                   ))
               }
           </div>

         
        </div>
    )
}
 