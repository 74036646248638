import React from 'react';
import {createUseStyles} from 'react-jss';
import Divider from '../Components/Divider';
import theme from '../theme';



const useStyles = createUseStyles({
    root: {
        marginTop:'42px',
        marginBottom:'42px'

    },
    heading:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center'
    },

    container:{
        maxWidth:'1280px',
        display:'grid',
        gridTemplateColumns:'auto auto auto',
        margin:'32px auto 0 auto'
    },

    logo:{
        width:'250px',
        margin:'0 auto',
        "& img":{
            width:'100%'
        }
    },

    '@media screen and (max-width: 1024px)': {

    },

    '@media screen and (max-width: 600px)': { 
        container:{
            gridTemplateColumns:'auto'
        }
    },
})

export default function FamiliarTech() {
    const classes = useStyles()

    const data = [
        {
            image:'/images/ds_ml/logos/1.png',
            alt:''
        },
        {
            image:'/images/ds_ml/logos/2.png',
            alt:''
        },
        {
            image:'/images/ds_ml/logos/3.png',
            alt:''
        },
        {
            image:'/images/ds_ml/logos/4.png',
            alt:''
        },
        {
            image:'/images/ds_ml/logos/5.png',
            alt:''
        },
        {
            image:'/images/ds_ml/logos/6.png',
            alt:''
        },
        {
            image:'/images/ds_ml/logos/7.png',
            alt:''
        },
        {
            image:'/images/ds_ml/logos/8.png',
            alt:''
        },
        {
            image:'/images/ds_ml/logos/9.png',
            alt:''
        },
        {
            image:'/images/ds_ml/logos/10.png',
            alt:''
        },
        {
            image:'/images/ds_ml/logos/11.png',
            alt:''
        },
        {
            image:'/images/ds_ml/logos/12.png',
            alt:''
        }
    ]

    return (
        <div className={classes.root}>
           
           <div className={classes.heading}>
            <h1>Our Expertise</h1>
            <Divider marginTop={'24px'} marginBottom={'24px'}/>
            <h3>Transform and Innovate with InnoWise</h3>
           </div>
           <div className={classes.container}>
               {
                   data.map((item,index)=>(
                    <div className={classes.logo}>
                    <img src={item.image}/>
               </div>
                   ))
               }
           </div>


        </div>
    )
}
 