import React, { useState } from 'react';
import {createUseStyles} from 'react-jss';
import Divider from '../Components/Divider';
import theme from '../theme';

const useStyles = createUseStyles({
    root: {
      background:theme.background,
      paddingTop:'40px',
      paddingBottom:'40px'
    },
    container:{
        display:'grid',
        gridTemplateColumns:'1fr 1fr',
        gridGap:'32px',
        maxWidth:'1280px',
        margin:'0 auto',
        padding:'0px 24px',
        // placeItems:'center'
    },

    card1:{
        background:'white',
        boxSizing:'border-box',
        padding:'16px 32px 32px 32px',
        textAlign:'center',
        borderRadius:'4px',
        '& p':{
            fontSize:'1.2rem',
            color:'grey'
        }

    },

    headImage:{
        maxWidth:'256px'
    },

    card2:{
        // maxWidth:'1280px',
        // margin:'0 auto',
        padding:'32px 32px',
        background:'white',
        boxSizing:'border-box',
        borderRadius:'4px',
        gridColumn:'1 / span 2',
        display:'grid',
        gridTemplateColumns:'1fr 1fr',
        '& .left':{
            display:'grid',
            placeItems:'center'
            // display:'flex',
            // justifyContent:'center'
        },
        '& .right':{
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
           

        },
       
        

    },

    stubbornImage:{
        maxWidth:'400px'
    },
  

    '@media screen and (max-width: 1023px)': {
        container:{
            display:'grid',
            gridTemplateColumns:'1fr',
            gridGap:'32px',
            maxWidth:'100vw',
            margin:'0 auto',
            padding:'0px 24px',
            // placeItems:'center'
        },
        stubbornImage:{
            maxWidth:'80%'
        },
        card2:{
            gridColumn:1,
            gridTemplateColumns:'auto',
            '& img:':{
                width:'50px !important'
            },

            '& .left':{
                display:'grid',
                placeItems:'center'
                // display:'flex',
                // justifyContent:'center'
            },
            '& .right':{
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'center',
                '& .image:':{
                    maxWidth:'100%'
                },
    
            },
        }
    },

    '@media screen and (max-width: 600px)': {
        headImage:{
            maxWidth:'50%'
        },
        stubbornImage:{
            maxWidth:'100%'
        },
    },
})

export default function MissionVisionCoreValues() {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <div className={classes.card1}>
                    <img src={'/images/about_us/mission_icon.png'} className={classes.headImage}/>
                    <h1>Mission</h1>
                    <Divider marginTop={'16px'} marginBottom={'32px'}/>
                    <img src={'/images/about_us/quote_top.svg'} style={{marginBottom:'24px'}}/>
                    <p>
                        At InnoWise we believe that digital organizations will rule the future. InnoWise’s mission is to accelerate the digitization of information for our clients through simple, effective and easy to use technology solutions.
                    </p>
                    <img src={'/images/about_us/quote_bottom.svg'} style={{marginTop:'24px'}}/>

                </div>
                <div className={classes.card1}>
                    <img src={'/images/about_us/vision_icon.png'} className={classes.headImage}/>
                    <h1>Vision</h1>

                    <Divider marginTop={'16px'} marginBottom={'32px'}/>
                    <img src={'/images/about_us/quote_top.svg'} style={{marginBottom:'24px'}}/>
                    <p>
                    We passionately believe in the power of digital transformation to have transformational effect on organizations, it’s employees and customers. Our vision is to make digital transformation possible for everyone.                    </p>
                    <img src={'/images/about_us/quote_bottom.svg'} style={{marginTop:'24px'}}/>
                </div>

                <div className={classes.card2}>
                    <div className={'left'}>
                        <img src={'/images/about_us/values_icon.png'} className={classes.headImage}/>
                    </div>
                    <div className={'right'}>
                        <h1>Core Values</h1>
                        <Divider marginTop={'16px'} marginBottom={'32px'}/>
                        <img src={'images/about_us/core_right.png'} className={classes.stubbornImage} />
                        </div>
                </div>
               
            </div>
        </div>
    )
}
 