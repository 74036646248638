import React from 'react';
import {createUseStyles} from 'react-jss'
import theme from '../theme';

const useStyles = createUseStyles({
    root:{
        textAlign:'center',
        margin:'90px 16px 60px 16px'
    },
    knowMoreBtn:{
        background:theme.success,
        color:'white',
        padding:'8px 20px',
        borderRadius:'50px',
        border:'none',
        outline:'none',
        marginLeft:'32px',
        background:`linear-gradient(to right, ${theme.primaryColor} 50%, ${theme.iwRed} 50%)`,
        backgroundSize: '200% 100%',
        backgroundPosition:'right bottom',
        cursor:'pointer',
        transition:'all 0.4s ease-in-out',

        '&:hover':{
            backgroundPosition:'left bottom'
            // background:'green'

        }
    }
  })

export default function KnowMore() {
    const classes = useStyles()
    function contactUs(){
        window.location.href="/contact_us"
    }

    return (
        <div className={classes.root}>
            <h2 style={{display:'inline'}}>Want to know about our services?</h2> 
            <button className={classes.knowMoreBtn} onClick={contactUs}>CONTACT US</button>
        </div>
    )
}
