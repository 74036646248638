import React from 'react';
import {createUseStyles} from 'react-jss';
import theme from '../theme';





const useStyles = createUseStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    maxWidth: "1000px",
    margin: '0 auto'
  },

  left: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'space-between',
    '& div': {
      borderRight: '4px solid grey',
      height: '50%',
      margin: 'auto 0',

    }
  },
  right: {
    display: 'grid',
    placeItems: 'center'
  },




  '@media screen and (max-width: 1024px)': {

  },

  '@media screen and (max-width: 600px)': {

    root: {
      gridTemplateColumns: 'auto'
    },
    left: {

      '& div': {
        display: 'none'

      }
    },
    right:{
      marginTop:'16px'
    }
  },




})

export default function Section2() {
    const classes = useStyles()


    return (
        <div style={{background:theme.background, margin:'50px 0', padding:'50px 16px'}}>
          <div className={classes.root}>
          <div className={classes.left}>
              <img src={'/images/about_us/rpa_banner.svg'} style={{maxWidth:'350px'}}/>
              <div/>
          </div>
          <div className={classes.right}>
            <p style={{fontSize:'1.5rem', maxWidth:'350px', color:'grey'}}>
            InnoWise intelligent automation provides guaranteed success with predictable results while managing to scale at enterprise level
            </p>
          </div>
            
        </div>
        </div>
    )
}
 