import React from 'react';
import {createUseStyles} from 'react-jss';
import theme from '../theme';



const useStyles = createUseStyles({
    root: {
        marginTop:'2rem',
        display:'grid',
        gridTemplateColumns:'1fr 1fr'
    },

    leftContent:{
        boxSizing:'border-box',
        // width:'calc(50vw - 64px)',
    },
    background:{
        background:'url("/images/salesforce/portfolio_bg.png")',
        height:'768px',
        backgroundSize:'cover',
        backgroundPosition:'top center',
        boxSizing:'border-box',
        '& p':{
            color:'white',
            fontSize:'3rem',
            textAlign:'center'
        }
    },
    rightContent:{
        boxSizing:'border-box',
        // width:'50px'
        display:'grid',
        background:'#F7F7FB',
        placeItems:'center',
     
        
    },
    cards:{
        display:'grid',

        gridTemplateColumns:'auto auto',
        maxWidth:'600px',
        margin:'0 auto'
    },

    card:{
        boxSizing:'border-box',
        fontSize:'.8rem',
        width:'250px',
        margin:'16px',
        borderRadius:'8px 8px 4px 4px',
        background:'white',
        border:'1px solid lightgrey',
        '& .header':{
            boxSizing:'border-box',

            // background:'#FF5685',
            // color:'white',
            padding:'10px 16px',
            borderRadius:'8px 8px 0px 0px'
        },
        '& .content':{
            boxSizing:'border-box',
            '& ul':{
                lineHeight:'1.6rem',
                marginLeft:'18px'
            },
            borderRadius:'0px 0px 4px 4px',
            padding:'8px'
        },
    },
    



    '@media screen and (max-width: 1080px)': {
        card:{
            width:'200px'
        }
   
    },

    '@media screen and (max-width: 768px)': { 
        root:{
            gridTemplateColumns:'auto'
        },
        background:{
            height:'300px'
        },
        cards:{
            gridTemplateColumns:'auto auto'
        },
        card:{
            width:'250px'
        }
    },

    '@media screen and (max-width: 600px)': { 
        cards:{
            gridTemplateColumns:'auto'
        },
        card:{
            width:'calc(100vw - 32px)'
        }
    }
})

export default function ServicesPortfolio() {
    const classes = useStyles()

    const data= [
        {
            title:'Business Consulting & Advisory',
            background:'#FF5685',
            color:'white',
            points:[
                'Process Maturity & Analysis',
                'CRM Auditing',
                'CRM Blueprint and Roadmap Preparation',
                'Process Consulting ',
                'Vendor Evaluation',
                'Research And Dev',
            ]
        },
        {
            title:'Technology Consulting',
            background:'#D257FF',
            color:'white',
            points:[
                'Solution Architecture',
                'Application Rationalization',
                'Custom Development',
                'Data Load',
                'IntegrationLicenses Management',
            ]
        },
        {
            title:'Implementation & Integration',
            background:'#FED154',
            color:'black',
            points:[
                'Global Rollouts',
                'Product Customization and Configuration',
                'Development',
                
            ]
        },
        {
            title:'Support & Data Migration',
            background:'#90DFFD',
            color:'black',
            points:[
                'Application Administration',
                'Maintenance & Enhancements',
                'Shared Support Pool',
                
            ]
        },
        {
            title:'Q&A Testing Services',
            background:'#595959',
            color:'white',
            points:[
                'Functional Test',
                'Test Planning and Test Execution',
                'End to End Test Management',
                'On-going Bug Tracking',
                
            ]
        },
    ]


   
    return (
        <div className={classes.root}>
        <div className={classes.leftContent}>
            <div className={classes.background}>
                <div style={{
                     height:'100%',
                     boxSizing:'border-box',
                     width:'calc(100%)',
                     background:'rgba(0,0,0,0.65)',
                     display:'grid',
                     placeItems:'center',
                     boxSizing:'border-box',
                     padding:'32px'

                }}>
                    <p>Salesforce Services Portfolio</p>
                </div>
            </div>
        </div>
        <div className={classes.rightContent}>
           <div className={classes.cards}>
           {
                data.map((item, index)=>(
                    <div className={classes.card}>
                <div className='header' style={{background:item.background, color:item.color}}>
                    {item.title}
                </div>
                <div className='content'>
                    <ul>
                       {
                           item.points.map(listItem=>(
                            <li>{listItem}</li>
                            ))
                     
                       }

                    </ul>
                </div>
            </div>
                ))
            }
           </div>
        </div>

        </div>
    )
}
 