import React, { useEffect, useState } from 'react';
import {createUseStyles} from 'react-jss';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
// import data from '../data'

const useStyles = createUseStyles({
    root:{
        paddingBottom:'0px'
    },
    slide:{
        // height:'400px',
        // paddingTop:'20px',
        background:'url("/images/next/automation.jpg")',
        backgroundPosition: 'center',
        position:'relative',  
        height:'100%'
    }
    ,
    
    content:{
        paddingTop:'30px',
        paddingBottom:'40px',
        height:'100%',
        width:'100%', 
        background:'rgba(0,0,0,0.8)',
        zIndex:1,
        display:'grid',
        placeItems:'center',
        color:'white'
    }
  })

export default function Testimonials() {
    const classes = useStyles()
    const dataTemplate = {
        testimonials:[
            {
                text:'',
                authorImg:'',
                authorName:''
            }
        ]
    }
    const [data,setData] = useState(dataTemplate)

    useEffect(() => {
        fetch('data.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        return response.json();
      })
      .then(function(myJson) {
          console.log(myJson)
        setData({testimonials:myJson.testimonials})
      });
    }, [])

    return (
        <div className={classes.root}>
             <Carousel
                showArrows={false}
                showStatus={false}
                infiniteLoop={true}
                autoPlay={true}
                swipeable={true}
                emulateTouch={true}
                interval={6000}

             >
                {
                    data.testimonials.map((item, index)=>(
                        <div className={classes.slide}>
                    <div className={classes.content}>
                        <div>
                            <h1 style={{marginTop:'28px', marginBottom:'28px'}}>What our Customers are saying about us?</h1>
                            <p style={{maxWidth:'1000px', marginTop:'16px', padding:'16px'}}>{item.text}</p>
                            <div style={{marginTop:'16px'}}>
                                <img src={item.authorImg} style={{width:'72px', borderRadius:'50%', marginTop:'28px',marginBottom:'16px'}}/>
                                <p style={{fontSize:'.9rem', marginBottom:'32px'}}>{item.authorName}</p>
                            </div>
                        </div>
                    </div>
                </div>
                    ))
                }
               
            </Carousel>
     
        </div>
    )
}
 