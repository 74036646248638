import React from 'react';
import {createUseStyles} from 'react-jss';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "../overrides.css";
import theme from '../theme';




const useStyles = createUseStyles({
    root:{
        paddingBottom:'0px',
        overflow:'hidden',
        position:'relative'
    },
    slide1:{
        height:'720px',
        // backgroundImage:'url("/images/hero-img.png")',
        backgroundPosition:'right top',
        backgroundSize:'auto 720px',
        backgroundRepeat:'no-repeat',
        
        position:'relative',
    },
    slide2:{
        height:'720px',
        // backgroundImage:'url("/images/hero-img.png")',
        backgroundPosition:'right top',
        backgroundSize:'auto 720px',
        backgroundRepeat:'no-repeat',
        
        position:'relative',
    },
    slide3:{
        height:'720px',
        // backgroundImage:'url("/images/hero-img.png")',
        backgroundPosition:'right top',
        backgroundSize:'auto 720px',
        backgroundRepeat:'no-repeat',
        
        position:'relative',
    },
    video:{
        minWidth:'100%',
        minHeight:'100%',
        // zIndex:-1,

    },
    background:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:"720px",
        overflow:'hidden', 
    },
    leftMatter1:{
        position:'absolute',
        top:'180px',
        left:'40px'
    },
    leftMatter2:{
        position:'absolute',
        top:'180px',
        left:'40px'
    },
    leftMatter3:{
        position:'absolute',
        top:'180px',
        left:'40px'
    },
    heading:{
        color:'white',
        fontSize:"3.5rem"
    },
    subtitle:{
        color:'white',
        fontSize:"0.9rem"
    },
    learnMoreBtn:{
        border:'none',
        outline:'none',
        color:'white',
        background:`linear-gradient(to right, ${theme.primaryColor} 50%, ${theme.iwRed} 50%)`,
        backgroundSize: '200% 100%',
        backgroundPosition:'right bottom',
        padding:"8px 20px",
        borderRadius:'50px',
        marginTop:'48px',
        marginRight:'48px',
        cursor:'pointer',
        transition:'all 0.4s ease-in-out',

        '&:hover':{
            backgroundPosition:'left bottom'
            // background:'green'

        }
    },
    contactUsBtn:{
        color:'white',
        background:`linear-gradient(to right, #fff 50%, rgba(10,10,10,1) 50%)`,
        backgroundSize: '200% 100%',
        backgroundPosition:'right bottom',
        border:'2px solid white',
        outline:'none',
        padding:"6px 20px",
        borderRadius:'50px',
        cursor:'pointer',
        transition:'all 0.4s ease-in-out',

        '&:hover':{
            backgroundPosition:'left bottom',
            color:'black',
            fontWeight:600
            // background:'green'

        }

    },

    '@media screen and (max-width: 1024px)': {
        slide1:{
            backgroundImage:'none'
        },
        slide2:{
            backgroundImage:'none'
        },
        slide3:{
            backgroundImage:'none'
        },
        leftMatter:{
            left:'20px'
        },
        heading:{
            fontSize:'2.5rem'
        }

      },

    '@media screen and (max-width: 600px)': {
        slide1:{
            backgroundImage:'none'
        },
        slide2:{
            backgroundImage:'none'
        },
        slide3:{
            backgroundImage:'none'
        },
        leftMatter:{
            left:'20px'
        },
        heading:{
            fontSize:'2.5rem'
        },
        leftMatter1:{

            top:'18px',
            left:'20px'
        },
        leftMatter2:{

          
            left:'20px'
        },
        leftMatter3:{
           
            left:'20px'
        },

      },

    
   
  })

export default function HeroImage() {
    const classes = useStyles()


    return (
        <div className={classes.root}>
             <div className={classes.background}>
                    <video autoPlay muted loop className={classes.video}>
                        <source src="/videos/ai.mp4" type="video/mp4" />
                    </video>
                </div>
             <Carousel
                showArrows={false}
                showStatus={false}
                infiniteLoop={true}
                autoPlay={true}
                swipeable={true}
                emulateTouch={true}
                

             >
            
                <div className={classes.slide1}>
                    <div className={classes.leftMatter1} style={{ textAlign: 'left' }}>
                        <div className={classes.heading}>
                        Getting started with RPA?<br/>
                        Looking to Accelerate your RPA journey?<br/>
                        Looking for intelligent Automation?

                        </div>

                        <div className={classes.subtitle}>
                        InnoWise is helping companies globally to maximize RPA adoption and get the most out of their RPA programs. 


                        </div>
                        <button className={classes.learnMoreBtn} onClick={e=>document.getElementById('ourOfferings').scrollIntoView()}>LEARN MORE</button>
                        <button className={classes.contactUsBtn} onClick={e=>window.location.href='/contact_us'}>CONTACT US</button>
                    </div>
                </div>

                <div className={classes.slide2}>
                    <div className={classes.leftMatter2} style={{ textAlign: 'left' }}>
                        <div className={classes.heading}>
                        Transform and Automate DevOps and Testing

                        </div>

                        <div className={classes.subtitle}>
                        Fully automate your DevOps and Software/Application Testing.



                        </div>
                        <button className={classes.learnMoreBtn} onClick={e=>document.getElementById('InnovationsWith').scrollIntoView()}>LEARN MORE</button>
                        <button className={classes.contactUsBtn} onClick={e=>window.location.href='/contact_us'}>CONTACT US</button>
                    </div>
                </div>

                <div className={classes.slide3}>
                    <div className={classes.leftMatter3} style={{ textAlign: 'left' }}>
                        <div className={classes.heading}>
                        Digital transformation simplified

                        </div>

                        <div className={classes.subtitle}>
                        We are simplifying digital transformations journeys one process at a time. 

</div>
                        <button className={classes.learnMoreBtn} onClick={e=>document.getElementById('coe').scrollIntoView()}>LEARN MORE</button>
                        <button className={classes.contactUsBtn} onClick={e=>window.location.href='/contact_us'}>CONTACT US</button>
                    </div>
                </div>
           
               
            </Carousel>
     
        </div>
    )
}
 