import React from 'react';
import { createUseStyles } from 'react-jss';
import Divider from '../Components/Divider';
import theme from '../theme';


const useStyles = createUseStyles({
    root:{
        display:'grid',
        gridTemplateColumns:'1fr 1fr',
        maxWidth:'1280px',
        margin:'0 auto 60px auto'
    },
    left:{
        width:'100%',
        height:'500px',
        display:'flex',
        flexDirection:'column',
        boxSizing:'border-box',
        padding:'0 32px',
        // background:'red'
    },
    button:{
        outline:'none',
        border:'none',
        background:`linear-gradient(to right, ${theme.primaryColor} 50%, ${theme.iwRed} 50%)`,
        backgroundSize: '200% 100%',
        backgroundPosition:'right bottom',
        color:'white',
        padding:'8px 20px',
        borderRadius:'50px',
        marginRight:'16px',
        cursor:'pointer',
        transition:'all 0.4s ease-in-out',
        '&:hover':{
            backgroundPosition:'left bottom'
        }
        
    },
    leftImg:{
        maxWidth:'450px',
        maxHeight:'250px',margin:'0 auto',
        marginTop:'16px'
    },
    table:{
        boxSizing:'border-box',
        padding:'32px',
        height:'100%',
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-evenly'

    },
    tableRow:{
        display:'flex',
        alignItems:'center',
        marginBottom:'16px'
    },
    tableRowLeft:{
        marginRight:'16px'
    },

    '@media screen and (max-width: 769px)': { 
        root:{
            gridTemplateColumns:'auto'
        },
        table:{
            gridTemplateColumns:'auto',
            '& .left':{
                order:2
            },
            '& .right':{
                order:1,
                width:'100% !important',
                height:'250px !important'
                // background:'red'
                // width:'100vw'
            },
        },
        leftImg:{
            maxWidth:'100%'
        }
     

    },
   
 
  })

export default function AutomationAsService() {
    const classes = useStyles()
    const data = [
        {
            image:'/images/rpa/automation_strategy.png',
            description:'Predictable and lower costs.'
        },
        {
            image:'/images/rpa/automation_assessment.png',
            description:'Access to latest technologies, features and experiences. No more costly and time-consuming upgrades.'
        },
        {
            image:'/images/rpa/automation_implementation.png',
            description:'Unburden your IT from day-to-day management. Focus where it matters most.'
        },
        {
            image:'/images/rpa/automation_coe_setup.png',

            description:'Flexibility to upscale/downscale on demand.'
        },
    ]

    return (
        <div>
            <div style={{margin:'50px 20px'}}>
                <center>
                <h1 style={{fontSize:'2.5rem'}}>
                    Automation As A Service
                </h1>
                <Divider marginTop={'32px'} marginTop={'32px'}/>

                </center>
            </div>
            
           <div className={classes.root}>
           <div className={classes.left}>
               <p>InnoWise is constantly innovating so we can bring best of the automation technology experience, capabilities and benefits to our customers. 
                <br/>
                InnoWise is proud to offer fully managed Automation as s Service offering  to our customers through InnoWise automation cloud.</p>
                <img src='/images/rpa/cloudbot.png' className={classes.leftImg}/>
                <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'32px'}}>
                        <button className={classes.button} onClick={e=>window.location.href='/contact_us'}>Contact Us</button>
                        <div>to know more about our RPA services.</div>
                    </div>
                </div>
                <div className={classes.right}>
                    <center><h1>Benefits</h1></center>
                <div className={classes.table}>
                          {
                              data.map((item,index)=>(
                                <div className={classes.tableRow}>
                                <div className={classes.tableRowLeft} style={{
                                    background:item.color,
                                    // height:'64px',
                                    // width:'64px',
                                    display:'grid',
                                    placeItems:'center',
                                    borderRadius:'10px'
                                }}>
                                    <img src={item.image} style={{width:'64px', padding:'4px'}}/>
                                </div>
                                <div className={classes.salesmanRowRight}>
                                    <p>{item.description}</p>
                                    </div>
                           </div>
                              ))
                          }
                       </div>
                </div>
           </div>
        </div>
    )
}
