import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import Navbar from "../Components/Navbar";
import Footer from "../Home/Footer";
import Map from "./map";
import { theme } from "../theme";
import MapChart from "./MapChart";
import Swal from "sweetalert2";
import TopSection from "./TopSection";
const useStyles = createUseStyles({
  root: {
    // contain: "content"
  },
  contact_us_bg: {
    backgroundImage: `url("/images/about_us/about_us_bg.PNG")`,
    height: "40vh",
    backgroundPosition: "center 0",
    backgroundRepeat: "no-repeat",
    contain: "content",
    backgroundSize: "cover",
  },
  overlay: {
    background: "#095DC7bf",
    height: "100%",
    width: "100%",
    position: "absolute",
  },
  bg_text: {
    color: "white",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,50%)",
    position: "absolute",
    textAlign: "center",
  },
  body: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    // marginTop: "35px",
    alignItems: "center",
  },
  bodyItems: {
    flex: "0 0 25vw",
    margin: "0 20px 40px 20px",
    "& p": {
      textAlign: "justify",
      textJustify: "inter-word",
      fontSize: "14px",
    },
    "& img": {
      marginLeft: "auto",
      marginRight: "auto",
      display: "block",
    },
  },
  bodyItemsMap: {
    flex: "0 0 100vw",
    paddingTop: "50px",
    contain: "content",
    // margin:"20px",
    background: "#E3E3E3",
    "& h1": {
      textAlign: "center",
      marginBottom: "50px",
    },
    "& img": {
      display: "block",
      width: "100%",
      maxWidth: "50%",
      height: "auto",
      margin: "auto auto 30px auto",
    },
  },
  contactForm: {
    background: "#F5F5F5",
  },
  formInput: {
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: "16px",
    border: "3px solid lightgrey",
    background: "#F5F5F5",
    padding: "8px",
    width: "calc(100% - 54px)",
    outline: "none",
  },
  formInputBtn: {
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: "16px",
    padding: "8px",
    width: "calc(100% - 32px)",
    outline: "none",
    background: "#D63E46",
    border: "none",
    color: "white",
    fontSize: "1.2em",
    fontWeight: "bold",
    marginBottom: "16px",
  },
  "@media (max-width: 1024px)": {
    bodyItems: {
      flex: "0 0 47vw",
    },
    bodyItemsMap: {
      "& img": {
        maxWidth: "70%",
      },
    },
  },
  "@media (max-width: 800px)": {
    bodyItems: {
      flex: "0 0 95vw",
    },
  },
  "@media (max-width: 420px)": {
    bodyItems: {
      flex: "0 0 95vw",
      "& img": {
        width: "70vw",
      },
    },
    bodyItemsMap: {
      "& img": {
        maxWidth: "90%",
      },
    },
  },
});

export default function ContactUs() {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [query, setQuery] = useState("");
  function handleOnSubmit(e) {
    e.preventDefault();
    console.log(name);
    var bod = {
      name: name,
      email: email,
      company: company,
      jobTitle: jobTitle,
      phoneNumber: phoneNumber,
      query: query,
    };
    e.target.reset();
    fetch("https://innowise.us/api/sendMail", {
      method: "post",
      body: JSON.stringify(bod),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log("Created Gist:", data.html_url);
        e.target.reset();
        Swal.fire("Thank you!", "Query Submitted", "success");
        // alert("Query Submitted");
        setCompany("")
        setEmail("")
        setJobTitle("")
        setName("")
        setPhoneNumber("")
        setQuery("")
      });
  }
  return (
    <div className={classes.root} style={{ position: "realtive" }}>
      <Navbar />

      <div style={{ paddingTop: "55px" }}>
        <TopSection />
        <div className={classes.body}>
          <div className={classes.bodyItems}>
            <h1>Want A Free Consultation?</h1>
            <img
              src="/images/contact_us/inquiry-now-discussion.webp"
              alt="propriety profile"
            />
            <p>
              Would you like to speak to one of our technical experts over the
              phone? Just submit your details and we’ll be in touch shortly. You
              can also email us at info@innowise.us if you would prefer
            </p>
          </div>
          <div className={classes.bodyItems}>
            <div className={classes.contactForm}>
              <form onSubmit={handleOnSubmit}>
                <input
                  className={classes.formInput}
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  className={classes.formInput}
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  className={classes.formInput}
                  placeholder="Job Title"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                />
                <input
                  className={classes.formInput}
                  placeholder="Company"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                />
                <input
                  className={classes.formInput}
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <textarea
                  rows="5"
                  className={classes.formInput}
                  placeholder="Type in your request/inquiry"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                ></textarea>
                <button
                  type="submit"
                  className={classes.formInputBtn}
                  style={{ cursor: "pointer" }}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
          {/* <div className={classes.bodyItemsMap}>
                    <h1>U.S. Headquarters</h1>
                    <iframe title="google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3464.565244467062!2d-95.84709238504975!3d29.732350281996872!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864123979dd78b81%3A0x1db286db2a6509ac!2s3434+Duplin+Creek+Dr%2C+Katy%2C+TX+77494%2C+USA!5e0!3m2!1sen!2suk!4v1552033675372" id="ContentPlaceHolder1_myMap" width="100%" height="350" frameborder="0" style={{ border: "0" }} allowfullscreen=""></iframe>
                </div> */}

          {/* commenting out the map */}
          {/* <div className={classes.bodyItemsMap} style={{ background: "white" }}>
            <h1>Global Presence</h1> */}
          {/* <img src="/images/contact_us/map.png" alt="globalPresence" /> */}
          <div>
            {/* <Map/> */}
            {/* <MapChart />
            </div> */}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
