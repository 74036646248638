import React from 'react';
import { createUseStyles } from 'react-jss';
import Divider from '../Components/Divider';
import theme from '../theme';


const useStyles = createUseStyles({
    root:{
        display:'grid',
        gridTemplateColumns:'1fr 1fr',
        // background:theme.background
    },
    left:{
        background:'black',
        width:'100%',
        height:'1000px',
        color:'white',
        display:'flex',
        flexDirection:'column',
        alignItems:'space-between',
        justifyContent:'space-evenly',
        borderLeft:'16px solid white'
    },
    button:{
        outline:'none',
        border:'none',
        background:`linear-gradient(to right, ${theme.primaryColor} 50%, ${theme.iwRed} 50%)`,
        backgroundSize: '200% 100%',
        backgroundPosition:'right bottom',
        color:'white',
        padding:'8px 20px',
        borderRadius:'50px',
        marginRight:'16px',
        cursor:'pointer',
        transition:'all 0.4s ease-in-out',
        '&:hover':{
            backgroundPosition:'left bottom'
        }
        
    },
    table:{
        boxSizing:'border-box',
        padding:'32px',
        height:'100%',
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-evenly'

    },
    tableRow:{
        display:'flex',
        alignItems:'center',
        marginBottom:'16px'
    },
    tableRowLeft:{
        marginRight:'16px'
    },

    '@media screen and (max-width: 769px)': { 
        root:{
            gridTemplateColumns:'auto'
        },
        left:{
            '& img':{
                display:'none',
                
            },
            height:'300px',
            borderLeft:'none'

        },
       
        table:{
            gridTemplateColumns:'auto',
            '& .left':{
                order:2
            },
            '& .right':{
                order:1,
                width:'100% !important',
                height:'250px !important'
                // background:'red'
                // width:'100vw'
            },
        },
     

    },
   
 
  })

export default function InnoWiseRPASection() {
    const classes = useStyles()
    const data = [
        {
            image:'/images/rpa/automation_strategy.png',
            title:'Automation Strategy',
            description:'A cost-effective Robotic Process Automation strategy with a result-oriented approach is always a key to success in automation. We help build these capabilities.'
        },
        {
            image:'/images/rpa/automation_assessment.png',
            title:'Automation Assessment',
            description:'We help you check the feasibility of automation. This includes shortlisting the relevant test cases for automation and selecting the right test tool that fits your requirement.'
        },
        {
            image:'/images/rpa/automation_implementation.png',
            title:'Automation Implementation',
            description:`We devise a meticulous and structured implementation approach which will define the contours of your overall strategy. Our certified RPA Developers then does the magic.`
        },
        {
            image:'/images/rpa/automation_coe_setup.png',
            title:'Automation COE Setup',
            description:'RPA CoE is an actual driver of innovation and digital transformation. Implementing RPA throughout an organization could lead to profound structural changes. We create the right CoE for your specific needs.'
        },
        {
            image:'/images/rpa/automation_manage_and_support.png',
            title:'Automation Manage and Support',
            description:'Our 24x7 support team can help you manage your automations, handle tracibility and reporting. Measure the success and inform the stakehlders with right timely information.'
        },
    ]

    return (
        <div className={classes.root}>
            <div className={classes.left} >
                    <div>
                       <center>
                       <h1>InnoWise RPA Services</h1>
                       <Divider marginTop={'28px'} marginTop={'32px'}/>

                       </center>
                    </div>
                    <img src='/images/rpa/design.png' style={{maxWidth:'90%', margin:'0 auto'}}/>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <button className={classes.button} onClick={e=>window.location.href='/contact_us'}>Contact Us</button>
                        <div>to know more about our RPA services.</div>
                    </div>
                </div>
                <div className={classes.right}>
                <div className={classes.table}>
                          {
                              data.map((item,index)=>(
                                <div className={classes.tableRow}>
                                <div className={classes.tableRowLeft} style={{
                                    background:item.color,
                                    // height:'64px',
                                    // width:'64px',
                                    display:'grid',
                                    placeItems:'center',
                                    borderRadius:'10px'
                                }}>
                                    <img src={item.image} style={{width:'80px', padding:'4px'}}/>
                                </div>
                                <div className={classes.salesmanRowRight}>
                                    <h2>{item.title}</h2>
                                    <p>{item.description}</p>
                                    </div>
                           </div>
                              ))
                          }
                       </div>
                </div>
        </div>
    )
}
