import React from 'react';
import {createUseStyles} from 'react-jss';
import "../overrides.css";
import Navbar from '../Components/Navbar';
import HeroSection from './HeroSection';
import Footer from '../Home/Footer'
import SalesforceServices from './SalesforceServices'
import ServicesPortfolio from './ServicesPortfolio';
import CRMBasedProducts from './CRMBasedProducts';
import CustomerStories from './CustomerStories'


const useStyles = createUseStyles({
    root: {

    },

    '@media screen and (max-width: 1024px)': {

    },

    '@media screen and (max-width: 600px)': { 

    },
})

export default function DSML() {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Navbar/>
            <div style={{paddingTop:'55px'}}>
                <HeroSection/>
                <SalesforceServices/>
                <ServicesPortfolio/>
                <CRMBasedProducts/>
                <CustomerStories/>
                <Footer/>
            </div>
         
        </div>
    )
}
 