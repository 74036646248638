import React from 'react';
import {createUseStyles} from 'react-jss'
import Divider from '../Components/Divider';
import theme from '../theme';

const useStyles = createUseStyles({
    root:{
        boxSizing:'border-box',
        margin:'50px 16px'
    },
    OfferingsInfo:{
        display:'grid',
        gridTemplateColumns:"450px auto",
        alignItems:'center',
        maxWidth:'1280px',
        margin:'0 auto'
        // justifyContent:"space-between",
        // paddingLeft:'100px',
        // paddingRight:'100px',
    },
    offeringInfoLeft:{
        // minWidth:'450px',
        // marginRight:'48px'
    },
    offeringInfoRight:{
        display:'flex',
        '& p':{
            marginBottom:'16px',
            textAlign:'justify'
        },
        '& ul':{
            marginLeft:'32px'
        }
        // minWidth:'450px',
        // marginRight:'48px'
    },


    verticalDivider:{
        height:'100px', borderRight:'3px solid '+theme.primaryColor, margin:'auto 32px auto 0'
    },


    '@media screen and (max-width: 1364px)': {
        OfferingsInfo:{
            gridTemplateColumns:'auto'
        },
        offeringInfoRight:{
            display:'flex',
            marginTop:'16px',
            '& p':{
                marginBottom:'16px',
                textAlign:'justify'
            },
            '& ul':{
                marginLeft:'32px'
            }
            // minWidth:'450px',
            // marginRight:'48px'
        },
        offeringInfoLeft:{
            textAlign:'center'
        },
        verticalDivider:{
            display:'none'
        }

      },

    // '@media screen and (max-width: 600px)': {

    //     OfferingsInfo:{
    //         display:'none'
    //     },

    
    
    //   },

  })

export default function OurCommitments() {
    const classes = useStyles()

   

    return (
        <div className={classes.root} id="ourOfferings">
            <div className={classes.OfferingsInfo}>
                <div>
                    <h1 className={classes.offeringInfoLeft}>The superpower of Cloud & DevOps at your service</h1>

                </div>
                <div className={classes.offeringInfoRight}>
                <div className={classes.verticalDivider}></div>

                    <div>
                    <p>
                    Meet your business challenges with the emerging cloud solutions and service from
InnoWise, including compute, data management, hybrid & multi-cloud, and
whatnot! 
                    </p>
                    <p style={{marginBottom:0}}>
                    DevOps – the seamless flow from development to IT operations – is that
superpower. With us, DevOps is about delivering technology change at the speed
you demand. We have seen it be truly transformational. 
                    </p>
                    </div>
                </div>
            </div>

     

            
        </div>
    )
}
