import React from 'react';
import {createUseStyles} from 'react-jss';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Divider from '../Components/Divider';
import "../overrides.css";
import theme from '../theme';



const useStyles = createUseStyles({
    root: {
        background:'#E2E2E2',
        paddingTop:'32px',
        paddingBottom:'24px'
    },
    productDevCarousel:{
        // margiTop:'100px',
        display:'grid',
        gridTemplateColumns:'1fr 1fr',
        maxWidth:'1024px',
        margin:'45px auto 0 auto',
        gridGap:'50px'

    },
    card:{
        fontSize:'.9rem',
        // lineHeight:'2rem',
        boxSizing:'border-box',
        margin:'16px',
        maxWidth:'480px',
        textAlign:'center',
        '& img':{
            width:'56px'
        },
        '& h3':{
            marginBottom:'16px'
        },
        '& p':{
            // textAlign:'justify'
        }
    },

    '@media screen and (max-width: 1024px)': {

    },

    '@media screen and (max-width: 600px)': {
        productDevCarousel:{
            gridTemplateColumns:'auto',
            maxWidth:'100%',
    
        },
    },
})

export default function WhatCanWeDo() {
    const classes = useStyles()

    const data = [
        {
            image:'/images/product_development/product_development.svg',
            heading:'Behavioral Prediction',
            description:'Predictive behavior modeling is the science of applying mathematical and statistical techniques to historical and       data in order to predict. eg. the future behavior of customers.'
        },
        {
            image:'/images/product_development/web_development.svg',
            heading:'Image Recognition',
            description:'Image recognition, in the context of machine vision, is the ability of software to identify objects, places, people, writing and actions in images. Our specialists can use machine vision technologies to achieve image recognition to help business.'
        },
        {
            image:'/images/product_development/front_end_development.svg',
            heading:'Decision Optimization',
            description:'Decision Optimization represents a family of optimization software that delivers prescriptive analytics capabilities to enable organizations to make better decisions and deliver improved business outcomes.'
        },
        {
            image:'/images/product_development/ui_ux_design.svg',
            heading:'Analysis of Data',
            description:'We pride ourselves on deriving truth and insight from any structured or unstructured data to identify and analyze the data Statistically and Graphically.'
        },
        {
            image:'/images/product_development/erp_software_development.svg',
            heading:'Semantic Interoperability',
            description:'Interoperability, or the ability for two systems to communicate effectively, is a key factor in the future of machine learning. For banking, healthcare, and other everyday industries, we’ve come to expect that the platforms we use to exchange information can communicate seamlessly whenever we need them to.'
        },
        {
            image:'/images/product_development/custom_software_development.svg',
            heading:'Big Data Analysis',
            description:"Our Team creates natural language generation (NLG) technology that can translate data into stories. By highlighting only the most relevant and interesting information, businesses can make quicker decisions regardless of the staff's experience with data or analytics."
        },
    ]

    return (
        <div className={classes.root}>
           <center>
               <h1>What can we do?</h1>
               <Divider marginTop={'32px'} marginTop={'32px'}/>

           </center>
           <div className={classes.productDevCarousel}>
               {
                   data.map((item,index)=>(
                    <div className={classes.card}>
                        <img src={item.image}/>
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                    </div>
                   ))
               }
           </div>

         
        </div>
    )
}
 