import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const useStyles = createUseStyles({
  root: {
    // width:'100%'
    color:'red'
  },
  main: {
    position: "relative",
    color:'#575757'

    // height:'(100% - 50px)'
  },
  topSection: {
    position: "relative",
    // zIndex:-10
  },
  backgroundImg: {
    background: 'url("/images/placeholder.jpg")',
    width: "100%",
    backgroundSize: "cover",
    height: "300px",
  },
  modal: {
    // width:'250px'
  },
  overlay: {
    height: "100%",
    width: "100%",
    background: "rgba(0,0,0,0.7)",

    zIndex: 1,
    // color:'white'
  },
  navAndCategory: {
    color: "white",
    display: "flex",
    alignItems: "center",
  },
  TopContent: {
    // display:'flex',
    position: "absolute",
    top: 0,
    color: "white",
    width: "calc(100% - 32px)",
    height: "calc(100%-32px)",
    padding: "16px",
  },
  useCaseTitle: {
    marginTop: "32px",
    fontSize: "1.7rem",
    maxHeight: "200px",
    overflow: "hidden",
  },
  articleBody: {
    // position:'absolute',
    // position:'relative',
    transform: "translatey(0px)",
    marginTop: "-100px",
    // zIndex:1000,
    width: "calc(100% - 64px)",
    // height:'500px',
    // boxShadow:'6px 6px 20px #474747',
    borderRadius: "10px",
    // zIndex:2
    background: "white",
    margin: "0 auto",
    padding: "16px",

    "& h1": {
      marginBottom: "16px",
      marginTop: "16px",
    },
    "& ul": {
      marginLeft: "32px",
    },
  },
  divider: {
    borderBottom: "2px solid lightgrey",
    margin: "16px",
  },
  downloadPDF: {
    // padding:'16px'
  },
  reqBtn: {
    background: "#E66927",
    color: "white",
    border: "none",
    outline: "none",
    padding: "8px 20px",
    fontSize: "1.1rem",
    cursor:"pointer"
    // borderRadius:'60px'
  },
  input: {
    padding: "8px 20px",
    fontSize: "1.1rem",
    border: "none",
    outline: "none",
    background: "#F2F2F2",
    minWidth: "122px",
    flex: 1,
    // marginRight:'16px'
  },
  "@media screen and (max-width: 600px)": {
    modal: {
      padding: "0px",
    },
    articleBody: {
      marginTop: 0,
      width: "calc(100% - 24px)",
      // padding:'16px',
      paddingLeft: "4px",
      paddingRight: "4px",
    },
    input: {
      textAlign: "center",
      width: "calc(100% - 40px)",
    },
    reqBtn: {
      marginTop: "16px",
      width: "100%",
    },
    divider: {
      marginLeft: "0px",
      marginRight: "0px",
    },
  },
});

export default function JobDetails(props) {
  const classes = useStyles();
  // const [open, setOpen] = useState(props.open);
  let open = props.open;
  const [email, setEmail] = React.useState(null);
  // const onOpenModal = () => setOpen(true);
  const onCloseModal = () => props.setOpen(false);
  const [showSendBtn, setShowSendBtn] = React.useState(true);
  let caseData = props.caseData;
  function sendUseCaseRequest() {
        console.log(email);
        var bod={
            email:email,
            useCaseSubject:caseData.article_title
        }

        fetch('https://innowise.us/api/useCaseRequest', {
    method: 'post',
    body: JSON.stringify(bod),
    headers:{
        "Content-Type":"application/json"
    }
  }).then(function(response) {
    return response.json();
  }).then(function(data) {
    console.log('Created Gist:', data.html_url);
    setEmail(null)
  });
    
  }

  const onRequest = () => setShowSendBtn(false);

  return (
    <div className={classes.root}>
      <Modal
        classNames={{
          modal: classes.modal,
        }}
        open={open}
        onClose={onCloseModal}
        showCloseIcon={false}
        center
      >
        <div className={classes.main}>
      
          <div className={classes.topSection}>
            <div className={classes.backgroundImg}>
              <div className={classes.overlay} />
            </div>
            <div className={classes.TopContent}>
              <div className={classes.navAndCategory}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={(e) => props.setOpen(false)}
                >
                  <div
                    style={{
                      display: "grid",
                      placeItems: "center",
                      height: "26px",
                      // fontSize:'20px',
                      width: "26px",
                      background: "white",
                      color: "black",
                      borderRadius: "50%",
                      marginRight: "8px",
                      // display:'inline-block'
                    }}
                  >
                    {"<"}
                  </div>
                  BACK
                </div>
                <div
                  style={{
                    height: "10px",
                    width: "10px",
                    background: "white",
                    borderRadius: "50%",
                    marginRight: "32px",
                    marginLeft: "32px",
                  }}
                />
                InnoWise   <input style={{height:0, width:0, opacity:0}} />
              </div>

              <div className={classes.useCaseTitle}>
                {props.jobData.job_title}<br/>
                <p style={{fontSize:'0.9rem'}}>{props.jobData.job_exp_required}</p>
              </div>
            </div>
          </div>
          <div className={classes.articleBody}>
            <p>{props.jobData.job_header_description}</p>
            <br/>
            <h3>Come Join Us - </h3>
            <p>{props.jobData.looking_for}</p>
            <p style={{fontSize:'0.9rem', marginTop:'10px'}}>Location - {props.jobData.location}</p>
            <br/>

            <h3>What we would like you to do –</h3>
            <ul>
              {
                props.jobData.work_expection_bullets.map((item,index)=>(
                  <li key={index}>{item}</li>
                ))
              }
            </ul>
            <br/>

            <h3>Our ideal candidate profile</h3>
            <ul>
            {
                props.jobData.work_profile_bullets.map((item,index)=>(
                  <li key={index}>{item}</li>
                ))
              }
            </ul>
            <br/>

            <h3>Some cool benefits of working with us-</h3>

            <p>
              {props.jobData.benefits}
            </p>

            

    
            
          </div>
          <div style={{width:'100%', display:'flex', justifyContent:'flex-end'}}>
          <button style={{
              background:'#4FBD4A',
              padding:'8px 20px',
              outline:'none',
              border:'none',
              color:'white',
              fontSize:'1em',
              marginRight:'16px',
              cursor:'pointer'
             
            }}
            onClick={e=>{
                props.setOpen(false)
                props.setOpenApply(true)
            }}
            >
              APPLY
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
