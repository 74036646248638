import React from "react";
import { createUseStyles } from "react-jss";
import Divider from "../Components/Divider";
import theme from "../theme";

const useStyles = createUseStyles({
  root: {
    marginBottom: "50px",
  },
  heading: {
    textAlign: "center",
    margin: "20px",
  },
  OfferingsInfo: {
    display: "grid",
    gridTemplateColumns:'auto auto',

    alignItems: "center",
    // justifyContent:"space-between",
    paddingLeft: "100px",
    paddingRight: "100px",
    marginBottom: "80px",
  },
  offeringInfoLeft: {
    maxWidth: "450px",
    marginRight: "48px",
    display:'flex',
    '& .divider':{
      // display:'none'
    }
  },
  offeringCarousel: {
    display: "flex",
    marginTop: "60px",
    marginBottom: "60px",
  },
  offeringCard: {
    // display:'flex',
    // flexDirection:'column',
    marginLeft: "10px",
    background: "white",
    // display:'grid',
    // gridTemplateColumns:'auto auto',
    zIndex: "1",
    boxShadow: " 0 2px 2px 0 rgba(0,0,0,0.1)",
    position: "relative",
    width: "calc(20% - 32px)",
    // background:'yellow',
    textAlign: "center",
    padding: "16px",
    "& img": {
      height: "50px",
    },
    "& h3": {
      height: "30px",
    },
    "& p": {
      height: "120px",
    },
  },
  verticalDivider: {
    position: "absolute",
    height: "100%",
    left: 0,
    top: 0,
    borderRight: "3px solid " + theme.error,
  },
  learnMoreBtn: {
    background: "black",
    color: "white",
    padding: "8px 20px",
    borderRadius: "50px",
    border: "none",
    outline: "none",
    // transform: "translatey(35px)",
    cursor: "pointer",
  },

  "@media screen and (max-width: 1024px)": {
    offeringCarousel: {
      display: "grid",
      marginTop: "0px",
      justifyItems:'center',
      margin:'0 auto'

    },
    offeringCard: {
      marginLeft: "0px",
      marginTop: "50px",
      width: "480px",
    },
    OfferingsInfo: {
      // display: "none",
      gridTemplateColumns:'auto',
    

    },
    offeringInfoLeft: {
      maxWidth: "unset",
      marginBottom:'32px',
     

      '& .divider':{
        display:'none'
      }
    },
  },

  "@media screen and (max-width: 600px)": {
    offeringCarousel: {
      display: "grid",
      marginTop: "0px",
    },
    offeringCard: {
      marginLeft: "0px",
      marginTop: "50px",
      width: "calc(100% - 32px)",
    },
    OfferingsInfo: {
      // display: "none",
      padding:'16px'
    },
    offeringInfoLeft: {
      '& .divider':{
        display:'none'
      }
    },
  },

  mainDiv: {
    background: theme.background,
    padding: "15px",
  },
});

export default function OurOfferings() {
  const classes = useStyles();

  function handleLearnMore() {
    document.getElementById("coe").scrollIntoView();
  }

  return (
    <div className={classes.root} id="ourOfferings">
      <div className={classes.heading}>
        <h1 style={{ fontSize: "2.5em" }}>Our Offerings</h1>
        <Divider marginTop={"26px"} marginBottom={"32px"} />
      </div>
      <div className={classes.OfferingsInfo}>
        <h1 className={classes.offeringInfoLeft}>
          Driving Digital Transformation for Fortune companies Globally
          <div
          className='divider'
          style={{
            height: "100px",
            borderRight: "3px solid " + theme.primaryColor,
            marginRight: "0px",
            marginLeft:'40px'
          }}
        ></div>
        </h1>
        
        <div>
          <p>
          Digital transformation is no longer nice to have, but a must have for
          any organization that wants to survive and thrive. But digital
          transformation means many things to many people and often it’s
          difficult to get started with transformation or have a clear strategy.
          We at InnoWise make is simple and easy to get started with your
          digital journey by focusing on transforming building blocks
          (processes) and drive tangible value creation in agile manner. Digital
          transformation does not have to be one monolith vision/initiative, but
          can be smaller, faster value added initiatives aligned with corporate
          business goals. With InnoWise’s focus on Automation, Data Science and
          Cloud, we are helping some of the largest corporations digitize their
          processes and become more efficient, innovative and compliant
          organizations.
          </p>
        </div>
      </div>

      <div className={classes.mainDiv}>
        <div className={classes.offeringCarousel}>
          <div className={classes.offeringCard} style={{ marginLeft: "0px" }}>
            <img src="./images/robot.svg"></img>
            <h3 style={{ marginTop: "16px" }}>RPA</h3>
            <p style={{ marginTop: "16px" }}>
              Allowing our customers to replace the monotonous with invention...{" "}
            </p>
            <button
              className={classes.learnMoreBtn}
              onClick={(e) => {
                window.location.href = "/rpa";
              }}
            >
              LEARN MORE
            </button>
          </div>

          <div className={classes.offeringCard}>
            <img src="./images/light-bulb.svg"></img>
            <h3 style={{ marginTop: "16px" }}>Product Development</h3>
            <p style={{ marginTop: "16px" }}>
              End-to-end product development & remote teams to deliver the best
              results...{" "}
            </p>
            {/* <div className={classes.verticalDivider}></div> */}
            <button
              className={classes.learnMoreBtn}
              onClick={(e) => {
                window.location.href = "/product_development";
              }}
            >
              LEARN MORE
            </button>
          </div>
          <div className={classes.offeringCard}>
            <img src="./images/targeting.svg"></img>
            <h3 style={{ marginTop: "16px" }}>ML & Data Science</h3>
            <p style={{ marginTop: "16px" }}>
              We are providing the power of ML to various business models to
              utilize the innovations...
            </p>
            {/* <div className={classes.verticalDivider}></div> */}
            <button
              className={classes.learnMoreBtn}
              onClick={(e) => {
                window.location.href = "/ds_ml";
              }}
            >
              LEARN MORE
            </button>
          </div>
          <div className={classes.offeringCard}>
            <img src="./images/clouds.svg"></img>
            <h3 style={{ marginTop: "16px" }}>Cloud and DevOps</h3>
            <p style={{ marginTop: "16px" }}>
              We deliver specific cloud solutions and seamlessly migrate your
              data...{" "}
            </p>
            {/* <div className={classes.verticalDivider}></div> */}
            <button
              className={classes.learnMoreBtn}
              onClick={(e) => {
                window.location.href = "/cloud_devops";
              }}
            >
              LEARN MORE
            </button>
          </div>
          <div className={classes.offeringCard}>
            <img src="./images/salesforce.svg"></img>
            <h3 style={{ marginTop: "16px" }}>Salesforce</h3>
            <p style={{ marginTop: "16px" }}>
              We help organizations ensure use of Salesforce to achieve growth
              and transformation goals...
            </p>
            {/* <div className={classes.verticalDivider}></div> */}
            <button
              className={classes.learnMoreBtn}
              onClick={(e) => {
                window.location.href = "/salesforce";
              }}
            >
              LEARN MORE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
