import React from "react";
import { createUseStyles } from "react-jss";
import "../overrides.css";
import theme from "../theme";

const useStyles = createUseStyles({
  root: {
    marginTop: "24px",
    background: "black",
    width: "100%",
    height: "350px",
    position: "relative",
    boxSizing: "border-box",
    display: "grid",
    placeItems: "center",
    padding: "50px",
  },
  content: {
    color: "white",
    maxWidth: "1366px",
    textAlign: "center",
    "& p": {
      margin: "24px",
    },
  },

  "@media screen and (max-width: 1024px)": {},

  "@media screen and (max-width: 600px)": {
    root: {
      height: "unset",
    },
  },
});

export default function Mission() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img
        src={"/images/careers/mission_top.png"}
        style={{ width: "128px", position: "absolute", top: 0, left: 0 }}
      ></img>
      <img
        src={"/images/careers/mission_bottom.png"}
        style={{ width: "128px", position: "absolute", right: 0, bottom: 0 }}
      ></img>
      <div className={classes.content}>
        <h1>Our Mission</h1>
        <div
          style={{
            borderBottom: "2px solid " + theme.iwRed,
            width: "150px",
            margin: "0 auto",
          }}
        ></div>
        <p>
          We are on a mission to accelerate the digitization of information
          using simple, easy to use and effective technology solutions. If you
          are passionate about technologies like Robotic Process Automation,
          Data Science, Salesforce and love to envision and build products, then
          you are in the right place.
        </p>
      </div>
    </div>
  );
}
