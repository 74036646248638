import React from 'react';
import {createUseStyles} from 'react-jss'
import theme from '../theme';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Divider from '../Components/Divider';

const useStyles = createUseStyles({
    root:{
        background:theme.secondaryColor,
        color:'white',
        paddingTop:'20px',
        paddingBottom:'60px'
        
    },
    subtitle:{
        position:'relative',
        marginTop:'10px'
        
    },
    underline:{
        borderBottom:'2px solid '+theme.error,
        width:'270px'
    },
    regions:{
        display:'flex',
        justifyContent:'space-evenly',
        background:'white',
        color:'black'
        // spac 
    },
    carousel:{
        // maxWidth:'100%',
        marginTop:'20px'
    },
    carouselContainer:{
        width:'30%',
        paddingTop:'30px',
        paddingBottom:'30px'

    },
    carouselImg:{
        width:'150px'
    },
    '@media screen and (max-width: 600px)': {
        regions:{
            display:'block'
        },
        carouselContainer:{
            width:'100%'
        }
    
      },
    
  })

export default function TrustedPartner() {
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1600 },
          items: 3,
          slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1366, min: 464 },
          items: 2,
          slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 1080, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        },
      };
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <center style={{margin:'40px 16px 60px 16px'}}>
            <h1 style={{fontSize:'2.5em'}}>Trusted partner for global enterprises</h1>
            <Divider marginTop={'28px'} marginBottom={'28px'}/>
            <h3 className={classes.subtitle}>Innovate and transform with InnoWise</h3>

            </center>
      
           <div className={classes.regions} style={{background:'#F8F8F8'}}>
                <div className={classes.carouselContainer} >
                 <center><h1>AMER</h1> </center>
                        <Carousel
                            swipeable={true}
                            draggable={true}
                            showDots={false}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={1000}
                            keyBoardControl={true}
                            customTransition="all .5s"
                            transitionDuration={2000}
                            containerClass={classes.carousel}
                            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                            //   deviceType={this.props.deviceType}
                            dotListClass="custom-dot-list-style"
                            // itemClass="carousel-item-padding-40-px"
                        >
                            <div style={{ textAlign: 'center' }}><img src="/images/amer/nrg.png" className={classes.carouselImg} /></div>
                            <div style={{ textAlign: 'center' }}><img src="/images/amer/servicenow.png" className={classes.carouselImg} /></div>
                            <div style={{ textAlign: 'center' }}><img src="/images/amer/chevron.png" className={classes.carouselImg} /></div>
                            <div style={{ textAlign: 'center' }}><img src="/images/amer/vmware.png" className={classes.carouselImg} /></div>

                        </Carousel>
                 </div>
                <div className={classes.carouselContainer} style={{background:'white'}}>
                 <center><h1>APAC</h1> </center>
                        <Carousel
                            swipeable={true}
                            draggable={true}
                            showDots={false}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={1000}
                            keyBoardControl={true}
                            customTransition="all .5s"
                            transitionDuration={2000}
                            containerClass={classes.carousel}
                            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                            //   deviceType={this.props.deviceType}
                            dotListClass="custom-dot-list-style"
                            // itemClass="carousel-item-padding-40-px"
                        >
                            <div style={{ textAlign: 'center' }}><img src="/images/apac/ey.png" className={classes.carouselImg} /></div>
                            <div style={{ textAlign: 'center' }}><img src="/images/apac/gp.png" className={classes.carouselImg} /></div>
                            <div style={{ textAlign: 'center' }}><img src="/images/apac/stl.png" className={classes.carouselImg} /></div>
                            <div style={{ textAlign: 'center' }}><img src="/images/apac/uipath.png" className={classes.carouselImg} /></div>

                        </Carousel>
                 </div>
                <div className={classes.carouselContainer}>
                 <center><h1>EMEA</h1> </center>
                        <Carousel
                            swipeable={true}
                            draggable={true}
                            showDots={false}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={1000}
                            keyBoardControl={true}
                            customTransition="all .5s"
                            transitionDuration={2000}
                            containerClass={classes.carousel}
                            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                            //   deviceType={this.props.deviceType}
                            dotListClass="custom-dot-list-style"
                            // itemClass="carousel-item-padding-40-px"
                        >
                            <div style={{ textAlign: 'center' }}><img src="/images/emea/hoe.png" className={classes.carouselImg} /></div>
                            <div style={{ textAlign: 'center' }}><img src="/images/emea/atlas.png" className={classes.carouselImg} /></div>
                            <div style={{ textAlign: 'center' }}><img src="/images/emea/fau.png" className={classes.carouselImg} /></div>
                            <div style={{ textAlign: 'center' }}><img src="/images/emea/basf.png" className={classes.carouselImg} /></div>
        
                        </Carousel>
                 </div>
           </div>

        </div>
    )
}
