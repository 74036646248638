import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Swal from "sweetalert2";

const useStyles = createUseStyles({
  root: {
    // width:'100%'
    color:'red'
  },
  main: {
    position: "relative",
    color:'#575757',
    // width:'500px'

    // height:'(100% - 50px)'
  },
  topSection: {
    position: "relative",
    // zIndex:-10
  },
  backgroundImg: {
    background: 'url("/images/placeholder.jpg")',
    width: "100%",
    backgroundSize: "cover",
    height: "300px",
  },
  modal: {
    // width:'250px'
  },
  overlay: {
    height: "100%",
    width: "100%",
    background: "rgba(0,0,0,0.7)",

    zIndex: 1,
    // color:'white'
  },
  navAndCategory: {
    color: "white",
    display: "flex",
    alignItems: "center",
  },
  TopContent: {
    // display:'flex',
    position: "absolute",
    top: 0,
    color: "white",
    width: "calc(100% - 32px)",
    height: "calc(100%-32px)",
    padding: "16px",
  },
  useCaseTitle: {
    marginTop: "32px",
    fontSize: "1.7rem",
    maxHeight: "200px",
    overflow: "hidden",
  },
  articleBody: {
    // position:'absolute',
    // position:'relative',
    transform: "translatey(0px)",
    marginTop: "-100px",
    // zIndex:1000,
    width: "calc(100% - 64px)",
    // height:'500px',
    // boxShadow:'6px 6px 20px #474747',
    borderRadius: "10px",
    // zIndex:2
    background: "white",
    margin: "0 auto",
    padding: "16px",

    "& h1": {
      marginBottom: "16px",
      marginTop: "16px",
    },
    "& ul": {
      marginLeft: "32px",
    },
  },
  divider: {
    borderBottom: "2px solid lightgrey",
    margin: "16px",
  },
  downloadPDF: {
    // padding:'16px'
  },
  reqBtn: {
    background: "#E66927",
    color: "white",
    border: "none",
    outline: "none",
    padding: "8px 20px",
    fontSize: "1.1rem",
    cursor:"pointer"
    // borderRadius:'60px'
  },
  input: {
    padding: "8px 20px",
    fontSize: "1.1rem",
    border: "none",
    outline: "none",
    background: "#F2F2F2",
    minWidth: "122px",
    flex: 1,
    width:'-webkit-fill-available'
    // marginRight:'16px'
  },
  formInput:{
    width:'calc(100% - 32px)',
    outline:'none',
    border:'none',
    background:'#f1f1f1',
    padding:'8px 16px',
    borderRadius:'4px',
    marginTop:'16px'
  },
  footer:{
    width:'calc(100% - 32px)',
    display:'flex',
    justifyContent:'flex-end',
    // paddingRight:'8px',
    paddingBottom:'12px'
  },
  "@media screen and (max-width: 600px)": {
    modal: {
      padding: "0px",
    },
 
    articleBody: {
      marginTop: 0,
      width: "calc(100% - 24px)",
      // padding:'16px',
      paddingLeft: "4px",
      paddingRight: "4px",
    },
    input: {
      textAlign: "center",
      width: "calc(100% - 40px)",
    },
    reqBtn: {
      marginTop: "16px",
      width: "100%",
    },
    divider: {
      marginLeft: "0px",
      marginRight: "0px",
    }, 
  },
});

export default function ApplyForJob(props) {
  const classes = useStyles();
  let open = props.open;

  const onCloseModal = () => props.setOpen(false);
  const [email,setEmail]=React.useState('');
  const [name, setName] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [contactNo, setContactNo] = React.useState('')
  const [resume, setResume] = React.useState(null)
  const [coverLetter, setCoverLetter] = React.useState(null)

  function onSubmit(e){
    e.preventDefault();
    // alert("Form Submitted")
    console.log(
      `
      email: ${email},
      name: ${name},
      contactNo: ${contactNo},
      resume: ${resume},
      coverLetter: ${coverLetter}
      description: ${description}
       `
    )

    let form_data = new FormData();
    form_data.append('email', email)
    form_data.append('name', name)
    form_data.append('phNumber', contactNo)
    form_data.append('coverLetter', coverLetter)
    form_data.append('resume', resume)
    form_data.append('description', description)
    form_data.append('jobTitle', props.jobData.job_title)

    fetch(
      'https://innowise.us/api/applyJob',
      {
        method:'post',
        body:form_data,

      }
    ).then(response=>{
      response.json()
    }).then(
      data=>{
        Swal.fire(
          'Done!',
          'You have successfully applied for this job',
          'success'
        ).then(()=>{
          props.setOpen(false)
        })
      }
    )
    .catch(error=>{
      Swal.fire(
        'Error!',
        'Please try again after some time...',
        'error'
      ).then(()=>{
        props.setOpen(false)
      })
    })

  }


  return (
    <div className={classes.root}>
      <Modal
        classNames={{
          modal: classes.modal,
        }}
        open={open}
        onClose={onCloseModal}
        showCloseIcon={false}
        center
      >
        <div className={classes.main}>
          <form onSubmit={onSubmit}>
      
          <div className={classes.topSection}>
            <div className={classes.backgroundImg}>
              <div className={classes.overlay} />
            </div>
            <div className={classes.TopContent}>
              <div className={classes.navAndCategory}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={(e) => props.setOpen(false)}
                >
                  <div
                    style={{
                      display: "grid",
                      placeItems: "center",
                      height: "26px",
                      // fontSize:'20px',
                      width: "26px",
                      background: "white",
                      color: "black",
                      borderRadius: "50%",
                      marginRight: "8px",
                      // display:'inline-block'
                    }}
                  >
                    {"<"}
                  </div>
                  BACK
                </div>
                <div
                  style={{
                    height: "10px",
                    width: "10px",
                    background: "white",
                    borderRadius: "50%",
                    marginRight: "32px",
                    marginLeft: "32px",
                  }}
                />
                InnoWise   <input style={{height:0, width:0, opacity:0}} />
              </div>

              <div className={classes.useCaseTitle}> 
                {props.jobData.job_title}<br/>
                <p style={{fontSize:'0.9rem'}}>{props.jobData.job_exp_required}</p>
              </div>
            </div>
          </div>
          <div className={classes.articleBody}>
            <div>
              <h2>Application Form</h2>
              <div>
                <input type="text" placeholder="Enter Name" className={classes.formInput} required onChange={e=>setName(e.target.value)}/>
                <input type="email" placeholder="Email Address" className={classes.formInput} required onChange={e=>setEmail(e.target.value)}/>
                <input type="text" placeholder="Enter Contact Number" className={classes.formInput} onChange={e=>setContactNo(e.target.value)}/>
                <div className={classes.formInput}>
                <label for ="resume" style={{fontSize:'0.9rem', marginRight:'16px'}}>Upload Resume</label>
                <input type="file" id="resume" placeholder="Upload Resume" required onChange={e=>setResume(e.target.files[0])}/>
                </div>
                <div className={classes.formInput}>
                <label for ="cover_letter" style={{fontSize:'0.9rem', marginRight:'16px'}}>Upload Cover Letter</label>
                <input type="file" id="cover_letter" placeholder="" onChange={e=>setCoverLetter(e.target.files[0])}/>
                </div>
                <textarea rows={5} className={classes.formInput}  onChange={e=>setDescription(e.target.value)}/>
              </div>
            </div>
            

    
            
          </div>
          <div className={classes.footer}>
            <button style={{
              background:'#4FBD4A',
              padding:'8px 20px',
              outline:'none',
              border:'none',
              color:'white',
              fontSize:'1em',
              marginRight:'16px',
              cursor:'pointer'
            }}
            type='submit'>
              SUBMIT
            </button>
            <button style={{
              background:'#DBDBDB',
              padding:'8px 20px',
              outline:'none',
              border:'none',
              color:'grey',
              fontSize:'1em',
              cursor:'pointer'

            }}
            onClick={e=>props.setOpen(false)}>
              Cancel
            </button>
          </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
