import React from 'react';
import {createUseStyles} from 'react-jss';
import Divider from '../Components/Divider';
import theme from '../theme';



const useStyles = createUseStyles({
    root: {
        paddingTop:'42px',
        paddingBottom:'42px',
        background:'#F7F7FB'

    },
    heading:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center'
    },

    container:{
        // maxWidth:'1024px',
        // width:'fit-content',
        marginTop:'50px',
        display:'grid',
        gridTemplateColumns:'1fr 1fr 1fr 1fr 1fr 1fr',
        gridGap:'16px',
        margin:'16px auto 0 auto'
    },

    card:{
        boxSizing:'border-box',
        background:'white',
        boxShadow: " 0 2px 2px 0 rgba(0,0,0,0.1)",

        

        // margin:'0 auto',
      
        textAlign:'center',
        // width:'180px',
        padding:'16px',
        '& img':{
            width:'100px'
        }
    },

    contact:{
        marginTop:'40px',
        marginBottom:'10px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        '& button':{
            outline:'none',
            border:'none',
            background:`linear-gradient(to right, ${theme.primaryColor} 50%, ${theme.iwRed} 50%)`,
            backgroundSize: '200% 100%',
            backgroundPosition:'right bottom',
            color:'white',
            padding:'8px 20px',
            borderRadius:'50px',
            cursor:'pointer',
            transition:'all 0.4s ease-in-out',
            marginLeft:'16px'
        },
        '& button:hover':{
            backgroundPosition:'left bottom'
        }
    },

  





    '@media screen and (max-width: 1024px)': {
        container:{
            display:'flex',
            flexWrap:'wrap',
            justifyContent:'center',
        },
        card:{
            // borderRight:'none !important',
            //         width:'180px',


        }
    },

    '@media screen and (max-width: 600px)': { 
        contact:{
            boxSizing:'border-box',
            padding:'0 8px',
            display:'block',
            textAlign:'center',
            "& button":{
                marginTop:'8px'
            }
        }
    },
})

export default function FamiliarTech() {
    const classes = useStyles()

    const data = [
        {
            title:'Marketing Automation',
            image:'/images/salesforce/marketing_automation.png'
        },
        {
            title:'Sales Automation',
            image:'/images/salesforce/sales_automation.png'
        },
        {
            title:'Customer Support Automation',
            image:'/images/salesforce/customer_support_automation.png'
        },
        {
            title:'Customer and Portner Portals',
            image:'/images/salesforce/customer_and_partner_portals.png'
        },
        {
            title:'Custom Apps Development',
            image:'/images/salesforce/custom_apps_development.png'
        },
        {
            title:'Data Analysis',
            image:'/images/salesforce/data_analysis.png'
        },
    ]

   
    return (
        <div className={classes.root}>
           
           <div className={classes.heading}>
            <h1>Salesforce Services</h1>
            {/* <h3>Transform and Innovate with InnoWise</h3> */}
                <Divider marginTop={'32px'} marginTop={'32px'}/>
           </div>
           <div className={classes.container}>
              {
                  data.map((item, index)=>(
                    <div className={classes.card} key={item.index} >
                    <div style={{
                        display:'grid',
                        placeItems:'center',
                        height:'120px',
                        width:'100%'
                    }}>
                    <img src={item.image}/>

                    </div>
                    <p>{item.title}</p>
  
                </div>
                  ))
              }
           </div>
           <div className={classes.contact}>
              <p>Contact us for your Salesforce Automation needs</p>
              <button>Contact Us</button>
           </div>


        </div>
    )
}
 