import React from 'react';
import {createUseStyles} from 'react-jss';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "../overrides.css";
import theme from '../theme';




const useStyles = createUseStyles({
    root:{
        position:'relative'
    },
    top:{
        height:'500px',
        background:'url("images/rpa/background_hero.png")',
        backgroundPosition:'center',
        backgroundSize:'cover',
        width:'100%',
        '& h1':{
            fontSize:'3rem',
            marginLeft:'1rem'
        },
        '& .heading':{
            display:'grid',
                    background:'rgba(0,0,0,0.5)',
                    height:'100%',
                    width:'100%',
                    color:'white',
                    alignContent:'center',
                    justifyItems:'center'
        }
    },
    bottomBg:{
        height:'150px',
        // background:"#E5E5E5"
    },
    bottom:{
        position:'absolute',
        bottom:'10px',
        width:'80%',
        display:'flex',
        background:'white',
        height:'auto',
        left:'10%',
        minHeight:'200px',
        borderRadius:'16px',
        alignItems:'center',
        '& .heading':{
            width:'60%',
            display:'grid',
            placeItems:'center',
            boxSizing:'borderBox',
            padding:'30px',
            fontSize:'2.5rem',
            fontWeight:600,
            textAlign:'center',
            borderRight:'1px solid '+theme.iwRed
        },
        '& .content':{
            padding:'22px',
            color:"#7F7F7F",
            fontSize:'1.05rem'
        }
        // right:'0'

    },
    

    '@media screen and (max-width: 766px)': {
        bottom:{
            display:'grid',
            width:'calc(100% - 32px)',
            left:'16px',
            '& .heading':{
                width:'calc(100% - 72px)',
                boxSizing:'borderBox',
                margin:'0 auto',
                padding:'16px',
                fontSize:'2rem',
                fontWeight:600,
                borderRight:'none',
                borderBottom:'1px solid '+theme.iwRed
            },
            '& .content':{
                padding:'10px 20px 20px 20px',
                color:"#7F7F7F"
            }
            
        }
      },

    '@media screen and (max-width: 600px)': {
       top:{
           '& .heading':{
               alignContent:'unset',
               paddingTop:'16px'
           }
       }

      },

    
   
  })

export default function HeroImage() {
    const classes = useStyles()


    return (
        <div className={classes.root}>
            <div className={classes.top}>
                <div className={'heading'}>
                    <h1>Intelligent Process Automation</h1>
                </div>
            </div>

            <div className={classes.bottomBg}></div>

            <div className={classes.bottom}>
                <p className={'heading'}>Intelligent Automation</p>
                
                <div className={'content'}>
                    <p>Intelligent automation, a set of technologies combining process redesign, process automation and machine learning – is rapidly reshaping the global economy, with significant gains for organizations that adopt it.</p>
                    <p style={{float:'right'}}>McKinsey</p>

                </div>
            </div>
           
        </div>
    )
}
 