import React, { useEffect, useState } from 'react';
import {createUseStyles} from 'react-jss';
import 'react-multi-carousel/lib/styles.css';

import Carousel from 'react-multi-carousel';
import theme from '../theme';



const useStyles = createUseStyles({
    root: {
        paddingTop:'50px',
        paddingBottom:'50px',
    },
    carouselContainer:{
        maxWidth:'1280px',
        margin:'10px auto',
        // marginTop:'40px'
        // paddingLeft:'100px',
        // paddingRight:'100px'
    },
    slideItem:{
        
    },
    divider:{
        borderBottom:'2px solid '+theme.error, 
        width:'150px'
    },
    card:{
        background:'white',
        // height:'250px',
        boxSizing:'border-box',
        margin:'10px auto',
        boxShadow:'5px 5px 10px #8c8c8c',
        padding:'16px',
        width:'296px',

        '& img':{
            // height:'100%',
            // width:'100%'
            height:'280px',
            // margin:'0 auto'
        },

        "& center p":{
            fontSize:'0.9rem',
            color:'darkgrey',
            marginTop:'2px'
        }
    },

    // '@media screen and (max-width: 1024px)': {

    // },

    // '@media screen and (max-width: 600px)': {

    // },
})

export default function LeadershipCarousel() {
    const classes = useStyles()
 
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
          slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };

      const [data,setData]=useState([
          {
              image:"",
              name:'',
              designation:''
          }, 
        

 

      ])


      useEffect(() => {
        console.log("Something happened")

        fetch('data.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                console.log("inside fetch")
                // console.log(response.json())
                return response.json();
           
            })
            .then(function (myJson) {
                setData(myJson.Leadership )
            });
    }, [])
    return (
        <div className={classes.root}>
            <center style={{fontSize:'1.2rem'}}>
                <h1>Leadership</h1>
                <div className={classes.divider}/>
                </center>
           
            <Carousel
                swipeable={true} 
                draggable={true}
                  showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={false}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                customTransition="all .5s"
                transitionDuration={2000}
                containerClass={classes.carouselContainer}
                // removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                //   deviceType={this.props.deviceType}
                dotListClass="custom-dot-list-style"
                itemClass={classes.slideItem}
            >
            {
                data.map((item,index)=>(
                    <div className={classes.card}>
                <img src={item.image}/>
                    <center>
                    <h4>{item.name}</h4>
                    <p>{item.designation}</p>
                    </center>
                
             </div>
                ))
            }
         
        


            </Carousel>
         
        </div>
    )
}
 