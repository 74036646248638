import React, { useEffect, useState } from 'react';
import {createUseStyles} from 'react-jss';
import 'react-multi-carousel/lib/styles.css';
import Divider from '../Components/Divider'
import Carousel from 'react-multi-carousel';
import theme from '../theme';



const useStyles = createUseStyles({
    root: {
        paddingTop:'50px',
        paddingBottom:'50px',
        background:theme.background
    },
    carouselContainer:{
        maxWidth:'1280px',
        margin:'10px auto',
        // marginTop:'40px'
        // paddingLeft:'100px',
        // paddingRight:'100px'
    }, 
    slideItem:{
        
    },
    divider:{
        borderBottom:'2px solid '+theme.error, 
        width:'150px'
    },
    subHeading:{
        fontWeight:400,
        color:'grey',
        marginBottom:'25px'
    },

    bottomSection:{
        position:'relative',
        // border:'1px solid black',
        boxSizing:'border-box',
        padding:'32px',
        maxWidth:'1024px',
        margin:'0 auto',
        width:'fit-content',
        zIndex:1,

    },
    card:{ 
        // height:'250px',
        boxSizing:'border-box',
        margin:'10px auto',
        // boxShadow:'5px 5px 10px #8c8c8c',
        padding:'16px',
        width:'320px',
        cursor:'pointer',

        '& img':{
            // height:'100%',
            width:'100%',
            marginBottom:'8px'
        },

        "& h2":{
            
            // marginBottom:'0px'
            // lineHeight:'2px'
        },

        "& .designation":{
            fontSize:'0.9rem',
            color:'darkgrey',
            marginTop:'-4px'
            // lineHeight:'2px'
            
        },
        "& .description_formal":{
            fontSize:'0.9rem',
            textAlign:'justify',
            marginTop:'8px'
        }
    },
    socialMediaIcons:{
        marginTop:'8px',
        '& a img':{
            width:'28px',
            marginRight:'8px',
            
            
        }
    },
    casualSection:{
        display:'flex',
    },

    // '@media screen and (max-width: 1024px)': {

    // },

    '@media screen and (max-width: 600px)': {
        casualSection:{
            flexWrap:'wrap'

        }
    },
})

export default function InnovitesCarousel() {
    const classes = useStyles()

    const [data,setData] = React.useState([{
        "id":"5",
        "name":"Tanushri Bhattacharya",
        "designation":"Software Developer",
        "desc_formal":"MBA in Finance and possess an extensive 18+ years of experience in Finance and HR across various industries.",
        "desc_casual":"InnoWise is all about realizing the power of Digital transformation to drive a new era of growth, development and productivity. Our team is powered by creative minds and innovative technologies that build our success.",
        "image_formal":"/images/about_us/tanushri_formal.png",
        "image_casual":"/images/about_us/tanushri_casual.png",
        "twitter":"",
        "linkedin":"https://www.linkedin.com/in/tanushribhattacharya",
        "insta":"",
        "fb":""
    }])

    const [selected, setSelected] = useState(null)
 
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
          slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };


    useEffect(() => {
        fetch('data.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                console.log(myJson.TeamData)
                setData(myJson.TeamData )
            });
    }, [])

    function selectUser(userId){
        setSelected(userId)
    }

    return (
        <div className={classes.root}>
            <center style={{fontSize:'1.2rem'}}>
                <h1>Innovites</h1>             
                   <Divider marginTop={'26px'} marginBottom={'32px'}/>

                <h4 className={classes.subHeading}>Meet our superstars</h4>

                </center>
           
            <Carousel
                swipeable={true}
                draggable={true}
                  showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                customTransition="all .5s"
                transitionDuration={2000}
                containerClass={classes.carouselContainer}
                // removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                //   deviceType={this.props.deviceType}
                dotListClass="custom-dot-list-style"
                itemClass={classes.slideItem}
            >
            {
                data.map((item,index)=>(
                    <div className={classes.card} key={index} onClick={e=>selectUser(item.id)}>
                <img src={item.image_formal}/>
                    <h4>{item.name}</h4><p className='designation'>{item.designation}</p>
                    {/* <p className='description_formal'>{item.desc_formal}</p> */}
                    <div className={classes.socialMediaIcons}>
                        <a target="_blank" href={item.twitter}><img src={"/images/sm/twitter.svg"}alt=""/></a>
                        <a target="_blank" href={item.linkedin}><img src={"/images/sm/linkedin.svg"}alt=""/></a>
                        <a target="_blank" href={item.insta}><img src={"/images/sm/insta.svg"}alt=""/></a>
                        <a target="_blank" href={item.fb}><img src={"/images/sm/fb.svg"}alt=""/></a>

                    </div>
             </div>
                ))  
            }
         
            </Carousel>

            {
                data.map((item, index)=>(
                    <div className={classes.bottomSection} key={index} style={item.id==selected?{display:'block'}:{display:'none'}}>
                    <img style={{ position: 'absolute', top: '0px', left: '0px', zIndex: -1 }} src={"/images/dots.svg"} />
                    {/* <table width="100%">
                        <tr>
                            <td>
                                <div style={{
    
                                    background: `url(${item.image_casual})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    height: '350px',
                                    width: '350px',
                                    zIndex: 2
                                }}
                                />
                            </td>
                            <td style={{textAlign:'justify', verticalAlign:'top'}}>
                                <div style={{ margin: '16px' }}>
                                    <h3>{item.name}</h3>
                                    <small style={{ fontSize: '1rem', fontWeight: 600, color: 'grey' }}>{item.designation}</small>
                                    <p style={{marginTop:'16px'}}>
                                       {item.desc_casual}
                        </p>
                                </div>
                            </td>
                        </tr>
                    </table> */}
                    <div className={classes.casualSection}>
                            {/* <div style={{

                                background: `url(${item.image_casual})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                height: '350px',
                                width: '350px',
                                zIndex: 2,
                                flex:'0 0 350px'
                            }}
                            /> */}
                            <img src={item.image_casual} style={{width:'350px', height:'intrinsic'}}/>
                             <div style={{ margin: '16px' }}>
                                    <h3>{item.name}</h3>
                                    <small style={{ fontSize: '1rem', fontWeight: 600, color: 'grey' }}>{item.designation}</small>
                                    <p style={{marginTop:'16px'}}>
                                       {item.desc_formal}
                        </p>
                                </div>

                    </div>
    
    
    
                </div>
                ))
            }

        </div>

    )
}
 