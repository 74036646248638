import React, { useEffect, useState } from 'react';
import {createUseStyles} from 'react-jss'
import Divider from '../Components/Divider';
import theme from '../theme';
// import data from '../data';
import CaseDetails from './CaseDetails';

const useStyles = createUseStyles({
    root:{
        marginTop:"40px",
        marginBottom:'40px',
        // margin :'40px auto'
    },
    images:{
        marginTop:'50px',
        paddingBottom:'40px',
        display:'grid',
        gridTemplateColumns:'auto auto auto',
        // marginLeft:'100px',
        // marginRight:'100px',
        placeItems:'center',
        maxWidth:'1150px',
        gridGap:'30px'
    },
    imgCard:{
        position:'relative',
        width:'fit-content',
        height:'calc(100% - 7px)',
        overflow:'hidden',
        cursor:'pointer'
    },
    card1:{            
          transition:'1s ease all',
            width: "130px",
            height: 0,
            borderBottom: '336px solid rgba(140,46,44,.8)',
            borderRight: '100px solid transparent',
        // '&:after':{
        //     content:'"Transform"',
        //     position:'absolute',
        //     top:0,
        //     "&:hover":{
        //         color:'white'
        //     }
        // }, 
        "& div":{
            color:'white',
            position:'absolute',
            maxWidth:"150px",
            top:'130px',
            left:'16px'
        },
        "&:hover":{
            width:"100%"
        }
    },

    card2:{            
        transition:'1s ease all',
          width: "130px",
          height: 0,
          borderBottom: '336px solid rgba(14,25,55,.8)',
          borderRight: '100px solid transparent',
      // '&:after':{
      //     content:'"Transform"',
      //     position:'absolute',
      //     top:0,
      //     "&:hover":{
      //         color:'white'
      //     }
      // }, 
      "& div":{
          color:'white',
          position:'absolute',
          maxWidth:"150px",
          top:'130px',
          left:'16px'
      },
      "&:hover":{
          width:"100%"
      }
  },

  card3:{            
    transition:'1s ease all',
      width: "130px",
      height: 0,
      borderBottom: '336px solid rgba(0,0,0,.8)',
      borderRight: '100px solid transparent',
  // '&:after':{
  //     content:'"Transform"',
  //     position:'absolute',
  //     top:0,
  //     "&:hover":{
  //         color:'white'
  //     }
  // }, 
  "& div":{
      color:'white',
      position:'absolute',
      maxWidth:"150px",
      top:'130px',
      left:'16px'
  },
  "&:hover":{
      width:"100%"
  }
},

'@media screen and (max-width: 1024px)': {
    images:{
        gridTemplateColumns:'auto auto',
        maxWidth:'770px'
        
    }
  },

'@media screen and (max-width: 600px)': {
    images:{
        gridTemplateColumns:'auto',
    }
  },


 



  })

export default function TransformAndInnovate() {
    const classes = useStyles()
    const dataTemplate = {
        TransformAndInnovate:{
            card1:{
            industry: "",
            article_title: "",
            case_title: "",
            solution: "",
            benefits:[],
            image:""
            },
            card2:{
            industry: "",
            article_title: "",
            case_title: "",
            solution: "",
            benefits:[],
            image:""
            },
            card3:{
            industry: "",
            article_title: "",
            case_title: "",
            solution: "",
            benefits:[],
            image:""
            },
            card4:{
            industry: "",
            article_title: "",
            case_title: "",
            solution: "",
            benefits:[],
            image:""
            },
            card5:{
            industry: "",
            article_title: "",
            case_title: "",
            solution: "",
            benefits:[],
            image:""
            },
            card6:{
            industry: "",
            article_title: "",
            case_title: "",
            solution: "",
            benefits:[],
            image:""
            },
  }
    }
    const [data,setData] = useState(dataTemplate)
    const [showDetails,setShowDetails]=React.useState(false)
    const [caseData, setCaseData] = React.useState({benefits:[]})

    useEffect(() => {
        fetch('data.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        return response.json();
      })
      .then(function(myJson) {
          console.log(myJson.TransformAndInnovate)
        setData({TransformAndInnovate: myJson.TransformAndInnovate})
      });
    }, [])

    
    const handleCaseClick = (e, caseNumber)=>{
        // alert("clicked")
        setShowDetails(true)
        if(caseNumber==1){
            setCaseData(data.TransformAndInnovate.card1)
        }
        else if(caseNumber==2){
            setCaseData(data.TransformAndInnovate.card2)
        }
        else if(caseNumber==3){
            setCaseData(data.TransformAndInnovate.card3)
        }
        else if(caseNumber==4){
            setCaseData(data.TransformAndInnovate.card4)
        }
        else if(caseNumber==5){
            setCaseData(data.TransformAndInnovate.card5)
        }
        else if(caseNumber==6){
            setCaseData(data.TransformAndInnovate.card6)
        }else{
            setCaseData({benefits:[]})
        }

    }


    return ( 
        <div className={classes.root}id ="coe">
            <CaseDetails open={showDetails} setOpen = {setShowDetails} caseData ={caseData}/>

            <center>
            <h1 style={{fontSize:'2.5em'}}>Transform and Innovate</h1>
            <Divider marginTop={'28px'} marginBottom={'28px'}/>

            <h3 style={{position:'relative'}}>Our Customer Stories</h3>
            <div className={classes.images}>
                <div className={classes.imgCard} onClick={e=>handleCaseClick(e,1)}>
                    <img src='/images/tni/1.png'></img>
                    <div style={{position:'absolute', top:0, left:0, height:"100%", width:'100%', textAlign:'left', overflow:'hidden'}}>
                    <div className={classes.card1} >
                        <div>
                        <p>{data.TransformAndInnovate.card1.article_title}</p>
                        </div>
                    </div>
                  
                    </div>
                </div>
                <div className={classes.imgCard} onClick={e=>handleCaseClick(e,2)}>
                    <img src='/images/tni/2.png'></img>
                    <div style={{position:'absolute', top:0, left:0, height:"100%", width:'100%', textAlign:'left', overflow:'hidden'}}>
                    <div className={classes.card2} >
                        <div>
                            <p>                        {data.TransformAndInnovate.card2.article_title}
</p>
                        </div>
                    </div>
                  
                    </div>
                </div>
                <div className={classes.imgCard} onClick={e=>handleCaseClick(e,3)}>
                    <img src='/images/tni/3.png'></img>
                    <div style={{position:'absolute', top:0, left:0, height:"100%", width:'100%', textAlign:'left', overflow:'hidden'}}>
                    <div className={classes.card3} >
                        <div>
                            <p>
                            {data.TransformAndInnovate.card3.article_title}

                            </p>
                        </div>
                    </div>
                  
                    </div>
                </div>
                <div className={classes.imgCard} onClick={e=>handleCaseClick(e,4)}>
                    <img src='/images/tni/4.png'></img>
                    <div style={{position:'absolute', top:0, left:0, height:"100%", width:'100%', textAlign:'left', overflow:'hidden'}}>
                    <div className={classes.card3} >
                        <div>
                            <p>
                            {data.TransformAndInnovate.card4.article_title}

                            </p>
                        </div>
                    </div>
                  
                    </div>
                </div>
                <div className={classes.imgCard} onClick={e=>handleCaseClick(e,5)}>
                    <img src='/images/tni/5.png'></img>
                    <div style={{position:'absolute', top:0, left:0, height:"100%", width:'100%', textAlign:'left', overflow:'hidden'}}>
                    <div className={classes.card1} >
                        <div>
                            <p>
                            {data.TransformAndInnovate.card5.article_title}

                            </p>
                        </div>
                    </div>
                  
                    </div>
                </div>
                <div className={classes.imgCard} onClick={e=>handleCaseClick(e,6)}>
                    <img src='/images/tni/6.png'></img>
                    <div style={{position:'absolute', top:0, left:0, height:"100%", width:'100%', textAlign:'left', overflow:'hidden'}}>
                    <div className={classes.card2} >
                        <div>
                            <p>
                            {data.TransformAndInnovate.card6.article_title}

                            </p>
                        </div>
                    </div>
                  
                    </div>
                </div>
             
            </div>
            </center>
        </div>
    )
}
