import React from 'react';
import {createUseStyles} from 'react-jss';
import "../overrides.css";
import Navbar from '../Components/Navbar';
import Footer from '../Home/Footer'
import Mission from './Mission';
import OurPositions from './OurPositions'
import TestYourLimits from './TestYourLimits';
import TopSection from './TopSection';
import ApplyForJob from './ApplyForJob';



const useStyles = createUseStyles({
    root: {

    },

    '@media screen and (max-width: 1024px)': {

    },

    '@media screen and (max-width: 600px)': { 

    },
})

export default function Careers() {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Navbar/>
            <div style={{paddingTop:'55px'}}>
                <TopSection/>
                <TestYourLimits/>

                <Mission/>
                <OurPositions/>
        
                <Footer/>
            </div>
         
        </div>
    )
}
 