import React from "react";
import { createUseStyles } from "react-jss";
import Divider from "../Components/Divider";
import theme from "../theme";

const useStyles = createUseStyles({
  root: {
    marginBottom: "50px",
  },
  heading: {
    textAlign: "center",
    margin: "20px",
  },
  OfferingsInfo: {
    display: "flex",
    alignItems: "center",
    // justifyContent:"space-between",
    paddingLeft: "100px",
    paddingRight: "100px",
    marginBottom: "80px",
  },
  offeringInfoLeft: {
    minWidth: "450px",
    marginRight: "48px",
  },
  offeringCarousel: {
    display: "flex",
    marginTop: "60px",
    marginBottom: "60px",
  },
  offeringCard: {
    // display:'flex',
    // flexDirection:'column',
    marginLeft: "10px",
    background: "white",
    zIndex: "1",
    boxShadow: " 0 2px 2px 0 rgba(0,0,0,0.1)",
    position: "relative",
    width: "calc(20% - 32px)",
    // background:'yellow',
    textAlign: "center",
    padding: "16px",
    "& img": {
      height: "50px",
    },
    "& h3": {
      height: "30px",
    },
    "& p": {
      height: "160px",
    },
  },
  verticalDivider: {
    position: "absolute",
    height: "100%",
    left: 0,
    top: 0,
    borderRight: "3px solid " + theme.error,
  },
  learnMoreBtn: {
    background: "black",
    color: "white",
    padding: "8px 20px",
    borderRadius: "50px",
    border: "none",
    outline: "none",
    // transform: "translatey(35px)",
    cursor: "pointer",
  },

  "@media screen and (max-width: 1024px)": {
    offeringCarousel: {
      display: "grid",
      marginTop: "0px",
      maxWidth:'480px',
      margin:'0 auto'
    },
    offeringCard: {
      marginLeft: "0px",
      marginTop: "50px",
      width: "calc(100% - 32px)",
    },
    OfferingsInfo: {
      display: "none",
    },
  },

  "@media screen and (max-width: 600px)": {
    offeringCarousel: {
      display: "grid",
      marginTop: "0px",
    },
    offeringCard: {
      marginLeft: "0px",
      marginTop: "50px",
      width: "calc(100% - 32px)",
    },
    OfferingsInfo: {
      display: "none",
    },
  },

  mainDiv: {
    background: theme.background,
    padding: "15px",
  },
});

export default function OurOfferings() {
  const classes = useStyles();

  function handleLearnMore(){
    document.getElementById('coe').scrollIntoView()
}

  return (
    <div className={classes.root} id="ourOfferings">
      <div className={classes.heading}>
        <h1 style={{ fontSize: "2.5em" }}>Our Offerings</h1>
        <Divider marginTop={"26px"} marginBottom={"32px"} />
      </div>
      

      <div className={classes.mainDiv}>
        <div className={classes.offeringCarousel}>
          <div className={classes.offeringCard} style={{ marginLeft: "0px" }}>
          <img src='./images/rpa/1.png'></img>
            <h3 style={{ marginTop: "16px" }}>RPA</h3>
            <p style={{ marginTop: "16px" }}>
            Robotic Process Automation abbreviated as, RPA helps remove the monotounous tasks from your pipeline and improve overall performance.{" "}
            </p>
            <button className={classes.learnMoreBtn} onClick={handleLearnMore}>LEARN MORE</button>

          </div>

          <div className={classes.offeringCard}>
          <img src='./images/rpa/2.png'></img>
            <h3 style={{ marginTop: "16px" }}>Automation Testing</h3>
            <p style={{ marginTop: "16px" }}>
            User the power of Automation to perform Application, Software, Platform testing easily with right KPIs.{" "}
            </p>
            {/* <div className={classes.verticalDivider}></div> */}
            <button className={classes.learnMoreBtn} onClick={handleLearnMore}>LEARN MORE</button>

          </div>
          <div className={classes.offeringCard}>
          <img src='./images/rpa/3.png'></img>
            <h3 style={{ marginTop: "16px" }}>Intelligent Automation</h3>
            <p style={{ marginTop: "16px" }}>
            A combination of artificial intelligence (AI), machine learning, and process automation that is used to create smart business processes and workflows that think, learn, and adapt on their own.
            </p>
            {/* <div className={classes.verticalDivider}></div> */}
            <button className={classes.learnMoreBtn} onClick={handleLearnMore}>LEARN MORE</button>

          </div>
          <div className={classes.offeringCard}>
          <img src='./images/rpa/4.png'></img>
            <h3 style={{ marginTop: "16px" }}>OCR/Document Understanding</h3>
            <p style={{ marginTop: "16px" }}>
            Get rid of all the manual data entry from Invoices, Recipts etc. with the power of OCR and advanced Docuement understanding.
            </p>
            {/* <div className={classes.verticalDivider}></div> */}
            <button className={classes.learnMoreBtn} onClick={handleLearnMore}>LEARN MORE</button>

          </div>
          <div className={classes.offeringCard}>
          <img src='./images/rpa/5.png'></img>
            <h3 style={{ marginTop: "16px" }}>DevOps Automation</h3>
            <p style={{ marginTop: "16px" }}>
            Accelerate your deployment with the integration of DevOps and Automation. Measure & Improve flow.
            </p>
            {/* <div className={classes.verticalDivider}></div> */}
            <button className={classes.learnMoreBtn} onClick={handleLearnMore}>LEARN MORE</button>

          </div>
        </div>
      </div>
    </div>
  );
}
