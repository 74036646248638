import React from 'react';
import {createUseStyles} from 'react-jss'
import Divider from '../Components/Divider';
import theme from '../theme';

const useStyles = createUseStyles({
    root:{
        boxSizing:'border-box',
        margin:'50px 16px'
    },
    OfferingsInfo:{
        display:'grid',
        gridTemplateColumns:"450px auto",
        alignItems:'center',
        maxWidth:'1280px',
        margin:'0 auto'
        // justifyContent:"space-between",
        // paddingLeft:'100px',
        // paddingRight:'100px',
    },
    offeringInfoLeft:{
        // minWidth:'450px',
        // marginRight:'48px'
    },
    offeringInfoRight:{
        display:'flex',
        '& p':{
            marginBottom:'16px',
            textAlign:'justify'
        },
        '& ul':{
            marginLeft:'32px'
        }
        // minWidth:'450px',
        // marginRight:'48px'
    },


    verticalDivider:{
        height:'100px', borderRight:'3px solid '+theme.primaryColor, margin:'auto 32px auto 0'
    },


    '@media screen and (max-width: 1364px)': {
        OfferingsInfo:{
            gridTemplateColumns:'auto'
        },
        offeringInfoRight:{
            display:'flex',
            marginTop:'16px',
            '& p':{
                marginBottom:'16px',
                textAlign:'justify'
            },
            '& ul':{
                marginLeft:'32px'
            }
            // minWidth:'450px',
            // marginRight:'48px'
        },
        offeringInfoLeft:{
            textAlign:'center'
        },
        verticalDivider:{
            display:'none'
        }

      },

    // '@media screen and (max-width: 600px)': {

    //     OfferingsInfo:{
    //         display:'none'
    //     },

    
    
    //   },

  })

export default function OurCommitments() {
    const classes = useStyles()

   

    return (
        <div className={classes.root} id="ourOfferings">
            <div className={classes.OfferingsInfo}>
                <div>
                    <h1 className={classes.offeringInfoLeft}>Our commitment to bring values for our esteemed Customers</h1>
                    <Divider marginBottom={'32px'} marginTop={'32px'}/>


                </div>
                <div className={classes.offeringInfoRight}>
                <div className={classes.verticalDivider}></div>

                    <div>
                    <p>Product development, is the life-blood of companies and societies. The product could be a physical product such as an automobile, smartphone, or anything. It could also be services such as new IT solution, new manufacturing process, or a new real estate marketing concept.</p>
                    <p>All our successful product development actions provide the following:</p>
                    <ul>
                        <li>New value for customers</li>
                        <li>Improved society</li>
                        <li>Continued existence of the company</li>
                        <li>Driven by DevOps for CI/CD</li>
                    </ul>
                    </div>
                </div>
            </div>

     

            
        </div>
    )
}
