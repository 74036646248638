import React from 'react';
import {createUseStyles} from 'react-jss'
import Navbar from '../Components/Navbar';
import HeroImage from './HeroImage'
import KnowMore from './KnowMore';
import OurOfferings from './OurOfferings';
import TransformAndInnovate from './TransformAndInnovate';
import TrustedPartner from './TrustedPartner';
import PartnersCarousel from './PartnersCarousel'
import Testimonials from './Testimonials';
import Next from './Next';
import Footer from './Footer';
import CaseDetails from './CaseDetails';

const useStyles = createUseStyles({
    root:{

    },
 
  })

export default function AboutUs() {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Navbar/>
            <div style={{paddingTop:'55px'}}>
                <HeroImage/>
                <OurOfferings/>
                <KnowMore/>
                <TrustedPartner/>
                <TransformAndInnovate/>
                <PartnersCarousel/>
                <Testimonials/>
                <Next/>
            </div>
            <Footer/>
        </div>
    )
}
