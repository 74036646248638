import React, { useState } from 'react';
import {createUseStyles} from 'react-jss';
import Divider from '../Components/Divider';
import "../overrides.css";
import theme from '../theme';
import JobDetails from './JobDetails';
import ApplyForJob from './ApplyForJob';





const useStyles = createUseStyles({
    root: {
        
    },
    heading:{
        marginTop:'42px',
        marginBottom:'32px',
        textAlign:'center'
    },
    jobContainer:{
        padding:'16px',
        maxWidth:'1280px',
        display:'grid',
        gridTemplateColumns:'1fr 1fr 1fr 1fr',
        gridGap:'16px',
        margin:'0 auto'
    },
    card:{
        boxShadow:'1px 1px 4px grey',
        boxSizing:'border-box',
        padding:'16px',
        borderRadius:'4px',
        width:'100%',
        // maxWidth:'300px',
        '& h3':{
            marginBottom:'2px'
        },
        '& small':{
            fontSize:'0.9rem',
            color:'grey'
        },
        '& button':{
            marginTop:'12px',
            background:theme.iwRed,
            padding:'8px 20px',
            border:'none',
            outline:'none',
            color:'white',
            borderRadius:'5px',
            cursor:'pointer'
        }
    },

    foot:{
        marginTop:'32px',
        // maxWidth:'1024px',
        boxSize:'border-box',
        background:'#EAEBF1',
        padding:'20px',
        textAlign:'center',
        '& p':{
            maxWidth:'1024px',
            margin:'0 auto'
        }
    },
  

    '@media screen and (max-width: 1280px)': {
        jobContainer:{
         
            gridTemplateColumns:'1fr 1fr 1fr',
      
        },
    },

    '@media screen and (max-width: 768px)': {
        jobContainer:{
         
            gridTemplateColumns:'1fr 1fr',
      
        },
    },

    '@media screen and (max-width: 600px)': { 
        jobContainer:{
         
            gridTemplateColumns:'1fr',
      
        },
    },
})

export default function OurPositions() {
    const classes = useStyles()
    const [openApply,setOpenApply] = React.useState(false)

    const dataTemplate = [
        {
            title:'UiPath Developer',
            location:'Pune',
            work_expection_bullets:[],
            work_profile_bullets:[]

        },
    ]

    const [data,setData] = React.useState(dataTemplate)
    const [openDescription, setOpenDescription] = useState(false)
    const [jobData, setJobData] = React.useState(dataTemplate[0])

    React.useEffect(() => {
        fetch('jobPosting.json' 
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                console.log(myJson.JobOpenings)
                setData(myJson.JobOpenings)
            });
    }, [])

    

    return (
        <div className={classes.root} id='positions'>
             <JobDetails open={openDescription} setOpen={setOpenDescription} jobData={jobData} setOpenApply={setOpenApply}/>
            <ApplyForJob open={openApply} setOpen={setOpenApply} jobData={jobData}/>
            <div className={classes.content}>
                <div className={classes.heading}>
                    <h1>Our Positions</h1>
                    <Divider marginBottom={'32px'} marginTop={'32px'}/>
                </div>
                <div className={classes.jobContainer}>
                    {
                        data.map((item, index)=>(
                            <div className={classes.card}>
                        <h3 style={{cursor:'pointer'}} onClick={e=>{
                            setOpenDescription(true)
                            setJobData(item)
                        }}>{item.job_title}</h3>
                        <small>Location: {item.location}</small><br/>
                        <button onClick={e=>{
                            setOpenDescription(true)
                            setJobData(item)
                        }}>Apply</button>
                       
                    </div>
                        ))
                    }

                </div>
            </div>
            <div className={classes.foot}>
                <p>                Don’t see the position you are looking for? We still want to talk to you. Send us your resume at <a href="mailto:careers@innowise.us">careers@innowise.us</a>.
</p>
            </div>
        </div>
    )
}
 