import React from 'react'
import theme from '../theme'

export default function Divider(props) {
    return (
        <div style={{borderBottom:'4px solid '+theme.iwRed, width:'50px ', margin:'0 auto', marginTop:props.marginTop?props.marginTop:'0px', marginBottom:props.marginBottom?props.marginBottom:'0px'}}>
            
        </div>
    )
}
