import React, { useEffect, useState } from 'react';
import {createUseStyles} from 'react-jss';
import Divider from '../Components/Divider';

const useStyles = createUseStyles({
    root: {
        paddingTop:'50px',
        paddingBottom:'50px',
    },
    leadersContainer:{
        display:'grid',
        // display:'flex'
        gridTemplateColumns:'auto auto auto auto',
        maxWidth:'1280px',
        margin:'0 auto'
    },
    card:{
        // height:'250px',
        boxSizing:'border-box',
        margin:'10px',
        boxShadow:'0px 0px 7px #8c8c8c',
        padding:'16px',
        width:'296px',
        display:'grid',

        '& img':{
            // height:'100%',
            // width:'100%'
        },

        "& center p":{
            fontSize:'0.9rem',
            color:'darkgrey',
            marginTop:'2px'
        }
    },

    '@media screen and (max-width: 1450px)': {
        leadersContainer:{
            gridTemplateColumns:'auto auto auto',
            maxWidth:"940px"

        }
    },

    '@media screen and (max-width: 1024px)': {
        leadersContainer:{
            gridTemplateColumns:'auto auto',
            maxWidth:"720px"

        }
    },
    '@media screen and (max-width: 720px)': {
        leadersContainer:{
            gridTemplateColumns:'auto',
            maxWidth:"320px"

        }
    },
    
})

export default function Leadership() {
    const classes = useStyles()
    
    const [data,setData]=useState([
        {
            image:"",
            name:'',
            designation:''
        }, 
    ])

    useEffect(() => {
        console.log("Something happened")

        fetch('data.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                console.log("inside fetch")
                // console.log(response.json())
                return response.json();
           
            })
            .then(function (myJson) {
                setData(myJson.Leadership )
            });
    }, [])

    return (
        <div className={classes.root}>
            <center style={{fontSize:'1.2rem'}}><h1>Leadership</h1></center>
            <Divider marginTop={'26px'} marginBottom={'40px'}/>

            <div className={classes.leadersContainer}>

            {
                data.map((item,index)=>(
                    <div className={classes.card}>
                <img src={item.image} style={{height:'270px', margin:'0 auto'}}/>
                <center>
                    <h4 style={{color:'rgb(208,65,70)', marginTop:'8px'}}>{item.name} <a href={item.linked_in} target="_blank"><img src="/images/about_us/li.png" style={{width:'16px', marginLeft:'4px'}}/></a></h4>
                    <p>{item.designation}</p>
                </center>
                
            </div>
                ))
            }
         
           
            </div>
          
         
        </div>
    )
}
 