import React from 'react';
import {createUseStyles} from 'react-jss';
import Divider from '../Components/Divider';
import theme from '../theme';



const useStyles = createUseStyles({
    root: {
        marginTop:'42px'
    },
    heading:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center'
    },
    content:{
        fontSize:'0.9rem',
        margin:'0 auto',
        background:'#FBFBFD',
        marginTop:'32px'
    },
    salesman:{
        // maxWidth:'1366px',
        display:'grid',
        gridTemplateColumns:'1fr 1fr',
        margin:'0 auto',
        "& .right":{
            border:'10px solid white'
        }
        
    },
    fastrac:{
        // maxWidth:'1366px',
        display:'grid',
        gridTemplateColumns:'1fr 1fr',
        margin:'0 auto',

        '& .left':{
            border:'10px solid white'

        },
        '& .right':{
            boxSizing:'border-box',
            padding:'16px',
            display:'grid',
            placeItems:'center',
            '& ul':{
                marginLeft:'18px',
                lineHeight:'30px',
                color:'#7F7F7F'
            }
            // background:'red'
        }
        
    },
    salesmanTable:{
        boxSizing:'border-box',
        padding:'32px',
        height:'100%',
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-evenly'

    },
    salesmanRow:{
        display:'flex',
        alignItems:'center',
        marginBottom:'16px'
    },
    salesmanRowLeft:{
        marginRight:'16px'
    },
    '@media screen and (max-width: 1024px)': {

    },

    '@media screen and (max-width: 769px)': { 
        salesman:{
            gridTemplateColumns:'auto',
            '& .left':{
                order:2
            },
            '& .right':{
                border:'none',
                order:1,
                width:'100% !important',
                height:'250px !important'
                // background:'red'
                // width:'100vw'
            },
        },
        fastrac:{
            gridTemplateColumns:'auto',
            '& .left':{
                width:'100% !important',
                height:'250px !important',
                border:'none',

              
            },
            '& .right':{
                '& ul':{
                    lineHeight:'22px'
                }
            }
        }

    },
})

export default function CRMBasedProducts() {
    const classes = useStyles()

    const salesmanData = [
        {
            image:'/images/salesforce/crm_plugin.png',
            description:'Plug-in for CRM application to help sales through automated product recommendations.',
            color:'#FF5685'
        },
        {
            image:'/images/salesforce/crm_leverage.png',
            description:'Leverages CRM system data and gives product recommendations through sort of AI based algorithm for Upsell.',
            color:'#D257FF'

        },
        {
            image:'/images/salesforce/crm_sales.png',
            description:'Sales team gets ‘Customers who bought this, also bought that’ type of B2B product recommendations while working on an Opportunity in CRM system.',
            color:'#FED154'

        },
    ]

    const fastrac = [
        'Get similar cases when support agent working on a case',

        'Saves time to respond',

        'Leverage legacy data and find similar cases',

        'Increased efficiency for support agents',

        'Better Customer experience',

    ]

    return (
        <div className={classes.root}>
            
            <div className={classes.heading}>
            <h1>CRM Based Products</h1>
            {/* <h3>Transform and Innovate with InnoWise</h3> */}
            <Divider marginTop={'32px'} marginTop={'32px'}/>
           </div>

           <div className={classes.content}>
               <div className={classes.salesman}>
                   <div className='left'>
                       <div className={classes.salesmanTable} style={{maxWidth:'500px', margin:'0 auto'}}>
                          {
                              salesmanData.map((item,index)=>(
                                <div className={classes.salesmanRow}>
                                <div className={classes.salesmanRowLeft} style={{
                                    background:item.color,
                                    // height:'64px',
                                    // width:'64px',
                                    display:'grid',
                                    placeItems:'center',
                                    borderRadius:'10px'
                                }}>
                                    <img src={item.image} style={{width:'56px', padding:'4px'}}/>
                                </div>
                                <div className={classes.salesmanRowRight}>{item.description}</div>
                           </div>
                              ))
                          }
                       </div>
                   </div>
                   <div className='right' style={{
                    //    background:'red',
                    
                       background:'url("/images/salesforce/salesman.png")',
                       backgroundSize:'cover',
                       backgroundPosition:'center',
                       height:'450px',
                       width:'calc(100% - 20px)',
                   }}>
                       <div style={{
                           height:'100%',
                           width:'100%',
                           background:'rgba(0,0,0,0.65)',
                           display:'grid',
                           placeItems:'center'
                       }}>
                        <div style={{textAlign:'center', color:'white'}}>
                            <p style={{fontSize:'2.7rem'}}>Salesman</p>
                            <p>AI based CRM recommendation engine</p>

                        </div>
                       </div>
                   </div>
               </div>

               <div className={classes.fastrac}>
                   <div class='left' style={{
                    //    background:'red',
                       background:'url("/images/salesforce/fastrac.png")',
                       backgroundSize:'cover',
                       backgroundPosition:'center',
                       height:'450px',
                       width:'calc(100% - 20px)',
                   }}>
                       <div style={{
                           height:'100%',
                           width:'100%',
                           background:'rgba(0,0,0,0.65)',
                           display:'grid',
                           placeItems:'center'
                       }}>
                        <div style={{textAlign:'center', color:'white'}}>
                            <p style={{fontSize:'2.7rem'}}>FASTTRAC</p>
                            <p>Improve your customer service with fast resolution of customer cases/tickets.</p>

                        </div>
                       </div>
                   </div>
                   <div className='right'>
                       <div style={{maxWidth:'500px', margin:'0 auto'}}>
                            <center>
                                <h2 style={{marginBottom:'12px'}}>NLP based CRM solution for support agents to refer to ‘similar cases’ when working on customer cases/tickets</h2>
                            </center>
                            <ul>
                                {
                                    fastrac.map((item,index)=>(
                                        <li key={index}>{item}</li>
                                    ))
                                }
                            </ul>
                       </div>
                   </div>
               </div>

           </div>
         
        </div>
    )
}
 