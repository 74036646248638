import React from 'react';
import { createUseStyles } from 'react-jss';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "../../overrides.css";
import theme from '../../theme';




const useStyles = createUseStyles({
    root: {
        position: 'relative'
    },
    top: {
        height: '500px',
        background: 'url("/images/ivision/eula_hero.jpg")',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width: '100%',
        '& h1': {
            fontSize: '3rem',
            marginLeft: '1rem'
        },
        '& .heading': {
            display: 'grid',
            background: 'rgba(0,0,0,0.5)',
            height: '100%',
            width: '100%',
            color: 'white',
            alignContent: 'center',
            justifyItems: 'center'
        }
    },




    '@media screen and (max-width: 600px)': {
        top: {
            '& .heading': {
                alignContent: 'unset',
                paddingTop: '16px'
            }
        }

    },



})

export default function HeroImage() {
    const classes = useStyles()


    return (
        <div className={classes.root}>
            <div className={classes.top}>
                <div className={'heading'}>
                    <h1>iVision - End User License Agreement</h1>
                </div>
            </div>



        </div>
    )
}
