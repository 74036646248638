import React from 'react';
import { createUseStyles } from 'react-jss';
import "../overrides.css";
import theme from '../theme';




const useStyles = createUseStyles({
    root: {
        background: 'linear-gradient(to right, black 35%, white 0%)',
        height: '720px',
        display: 'grid',
        placeItems: 'center'

    },
    mainContainer: {
        height: '600px',
        display: 'grid',
        maxWidth: '1920px',
        gridTemplateColumns: '2fr 1fr',
        background: 'white',
        // boxShadow: '2px 2px 5px grey',
        margin: '16px'
    },
    imgContainer: {
        // transform:'translatex(400px)',
        // transition:'1s all',
        // background: 'red',
        height: "calc(100% - 32px)",
        // display: 'grid',
        // width: '600px',
        // gridTemplateColumns: '1fr 1fr 1fr',
        // '& img': {
        //     width: '100%'
        // },
        clipPath: 'polygon(0% 0%, 98% 0%, 70% 100%, 0% 100%)',
        // border:'5px solid black'
        // boxShadow:'5px 5px 5px black'

    },
    rightSection: {
        display: 'grid',
        placeItems: 'center',
        boxSizing: 'border-box',
        padding: '16px',
        '& p': {
            marginTop: '16px'
        },
        '& button': {
            padding: '8px 20px',
            outline: 'none',
            border: 'none',
            color: 'white',
            background: theme.iwRed,
            marginTop: "16px",
            fontSize: '1.1rem',
            background: `linear-gradient(to right, ${theme.primaryColor} 50%, ${theme.iwRed} 50%)`,
            backgroundSize: '200% 100%',
            backgroundPosition: 'right bottom',
            cursor: 'pointer',
            transition: 'all 0.4s ease-in-out',

            '&:hover': {
                backgroundPosition: 'left bottom'
                // background:'green'

            }
        }
    },


    '@media screen and (max-width: 768px)': {
        root: {
            background: 'white',
            height: 'auto'
        },
        mainContainer: {
            gridTemplateColumns: 'auto',
            height: 'auto',
            width: '100%',
            boxShadow: 'none',
            margin: 0,

        },
        imgContainer: {
            height: 'auto',
            width: '100%',
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 70%)',

        },
        rightSection: {
            marginTop: '-20px'
        }

    },
})

export default function TopSection() {
    const classes = useStyles()
    function exploreOpportunities() {
        document.getElementById('positions').scrollIntoView()
    }


    return (
        <div className={classes.root}>
            <div className={classes.mainContainer} >

                <div className={classes.imgContainer} style={{
                    padding: '16px'
                }}>
                    <div style={{
                        backgroundImage: 'url("/images/careers/grow_together.jpg")',
                        height: "100%",
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '100% center',

                    }} />
                </div>

                <div className={classes.rightSection} >
                    <div>
                        <div>
                            <h1>Let Us Grow Together</h1>
                            <div style={{ borderBottom: '4px solid ' + theme.iwRed, width: "50px", marginTop: '16px', marginBottom: '24px' }} />
                        </div>
                        <p style={{ maxWidth: '500px' }}>
                            We are building an amazing organization for amazing, creative and innovative people like you.
                            If you're ready to grow your career and do amazing work with next generation technologies, you’ve come to the right place.
                        </p>
                        <button onClick={exploreOpportunities}>Explore Opportunities</button>
                    </div>
                </div>

            </div>
        </div>
    )
}
