const theme = {
    primaryColor:"#243F8C",
    secondaryColor:"#3F3F3F",
    // success:"#85AF4B",
    success:'#d64047',
    error: "#E07670",
    iwRed:'#D64840',
    iwRedHover:'red',
    background:'#F7F7FB'
}

export default theme