import React from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";

const useStyles = createUseStyles({
  root: {
    background: "white",
    display: "flex",
    // border:'1px solid black',
    height: "55px",
    alignItems: "center",
  },
  logo: {
    height: "inherit",
    marginLeft: "16px",
  },
  logoContainer: {
    flex: 1,
    height: "42px",
  },
  navItems: {
    listStyleType: "none",
    "& li": {
      display: "inline",
      marginRight: "16px",
    },
    "& li a": {
      textDecoration: "none",
      color: "black",
      fontWeight: 600,
      fontSize: "0.85rem",
    },
  },
  hamburger: {
    display: "none",
    outline: "none",
    border: "none",
    background: "none",
    marginRight: "16px",
    cursor: "pointer",
    padding: "8px",
    // borderRadius:'50%'
    "& :hover": {
      background: "#d5d5d5",
    },
  },
  navItemsMobile: {
    background: "white",
    listStyleType: "none",
    padding: "16px",
    lineHeight: "2rem",
    "& li a": {
      textDecoration: "none",
      color: "black",
      fontWeight: 600,
      fontSize: "0.85rem",
    },
  },
  dropDown: {
    position: "relative",
    display: "inline-block",
    "& :hover": {
      display: "block",
    },
  },
  dropDownContent: {
    display: "none",
    position: "absolute",
    backgroundColor: "#f9f9f9",
    minWidth: "160px",
    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
    padding: "12px 16px",
    zZndex: 1,
  },

  "@media screen and (max-width: 600px)": {
    navItems: {
      display: "none",
    },
    hamburger: {
      display: "unset",
    },
    navItemsMobile: {
      // display:'unset'
    },
  },
});

export default function Navbar() {
  const classes = useStyles();
  const [showMenu, setShowMenu] = React.useState(false);
  const handleHamburger = (e) => {
    setShowMenu(!showMenu);
  };

  return (
    <div style={{ position: "fixed", zIndex: 5, width: "100%" }}>
      <div className={classes.root}>
        <div className={classes.logoContainer}>
          <img src="/images/iw-logo-png.png" className={classes.logo} />
        </div>
        <ul className={classes.navItems}>
          <li>
            <a href="/">HOME</a>
          </li>
          <li>
            <a href="/about_us">ABOUT</a>
          </li>
          <li>
            <div className="dropdown">
              <span>SERVICES</span>
              <div className="dropdown-content">
                <a href="/rpa">RPA</a>
                <a href="/ds_ml">{"ML & Data Science"}</a>
                <a href="/cloud_devops">Cloud & DevOps</a>
                <a href="/salesforce">Salesforce</a>
                <a href="/product_development">Product Development</a>
              </div>
            </div>
          </li>
          <li>
            <div className="dropdown">
              <span>RESOURCES</span>
              <div className="dropdown-content">
                <a href="/resources">CASE STUDIES</a>
                {/* <a href="#">BlOG</a> */}
              </div>
            </div>
          </li>
          <li>
            <a href="/careers">CAREERS</a>
          </li>
          <li>
            <a href="/contact_us">CONTACT</a>
          </li>
        </ul>
        <button
          onClick={(e) => handleHamburger(e)}
          className={classes.hamburger}
        >
          <img src="/images/ham.svg" style={{ height: "34px" }} />
        </button>
      </div>
      <ul
        className={classes.navItemsMobile}
        style={showMenu ? { display: "block" } : { display: "none" }}
      >
        <li>
          <a href="/">HOME</a>
        </li>
        <li>
          <a href="/about_us">ABOUT</a>
        </li>
        <li>
          <a href="/">SERVICES</a>
        </li>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "16px",
            fontSize: "0.8rem",
            fontWeight: 600,
            background: "#e5e5e5",
            borderRadius: "5px",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
        >
          <a href="/rpa" style={{ textDecoration: "none", color: "black" }}>
            RPA
          </a>
          <a href="/ds_ml" style={{ textDecoration: "none", color: "black" }}>
            {"ML & Data Science"}
          </a>
          <a
            href="/cloud_devops"
            style={{ textDecoration: "none", color: "black" }}
          >
            Cloud & DevOps
          </a>
          <a
            href="/salesforce"
            style={{ textDecoration: "none", color: "black" }}
          >
            Salesforce
          </a>
          <a
            href="/product_development"
            style={{ textDecoration: "none", color: "black" }}
          >
            Product Development
          </a>
        </div>
        <li>
          <a href="/resources">RESOURCES</a>
        </li>
        <li>
          <a href="/careers">CAREERS</a>
        </li>
        <li>
          <a href="/contact_us">CONTACT</a>
        </li>
      </ul>
    </div>
  );
}
