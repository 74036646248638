import React from 'react';
import {createUseStyles} from 'react-jss'
import Divider from '../Components/Divider';

const useStyles = createUseStyles({
    root:{
        marginBottom:'120px',
        marginTop:'40px'
    },
    imagesContainer:{
        display:'flex',
        justifyContent:'center'
    },
    images:{
        display:'grid',
        gridTemplateColumns:'auto auto auto',
        placeItems:'center',
        maxWidth:'1150px',
        gridGap:'30px'
    },
    card:{
        position:'relative',
        height:'250px',
        width:'370px',
        // cursor:'pointer'
        // background:'red'
    },
    bg1:{
        position:'absolute',
        background:'url("/images/next/learn.jpg")',
        height:'100%',
        width:'100%',
        backgroundPosition:'center',
        backgroundSize:'cover',
        zIndex:-2,
    },
    bg2:{
        position:'absolute',
        background:'url("/images/next/automation.jpg")',
        height:'100%',
        width:'100%',
        backgroundPosition:'center',
        backgroundSize:'cover',
        zIndex:-2
    },
    bg3:{
        position:'absolute',
        background:'url("/images/next/ai.jpg")',
        height:'100%',
        width:'100%',
        backgroundPosition:'center',
        backgroundSize:'cover',
        zIndex:-2
    },
    overlay1:{
        position:'absolute',
        top:0,
        left:0,
        background:"rgba(14,25,55,.8)",
        height:'100%',
        width:'100%',
    },
    overlay2:{
        position:'absolute',
        top:0,
        left:0,
        background:"rgba(0,0,0,.5)",
        height:'100%',
        width:'100%',
    },
    overlay3:{
        position:'absolute',
        top:0,
        left:0,
        background:"rgba(140,46,44,.7)",
        height:'100%',
        width:'100%',
    },
    text:{
        position:'absolute',
        top:0,
        left:0,
        display:'grid',
        placeItems:'center',
        height:'100%',
        width:'calc(100% - 32px)',
        color:'white',
        padding:'16px'
    },

    '@media screen and (max-width: 1024px)': {
        images:{
            gridTemplateColumns:'auto auto',
        },
    
      }, 
    '@media screen and (max-width: 600px)': {
        images:{
            gridTemplateColumns:'auto',
        },
    
      },

  })

export default function Next() {
    const classes = useStyles()


    return (
       <div className={classes.root} id='InnovationsWith'>
           <center>
               <h1 style={{fontSize:'2.5em'}}>NeXt_</h1>
               <Divider marginTop={'26px'} marginBottom={'32px'}/>

               <h2 style={{maxWidth:'1000px', marginTop:'16px', marginBottom:'36px'}}>We are continuously innovating to bring powerful
                next generation products and services to empower our clients</h2>
           </center>
           
            <div className={classes.imagesContainer}>
            <div className={classes.images}>
                <div className={classes.card}>
                    <div class={classes.bg1}></div>
                     <div className={classes.overlay1}></div>
                     <div className={classes.text}><span>Train and learn like never before... <br/><span style={{cursor:'pointer', textDecoration:'underline'}} onClick={e=>window.open('https://test-lab.vrevent.us', "_blank")}>Learn More</span></span></div>
                </div>
                <div className={classes.card}>
                    <div class={classes.bg2}></div>
                     <div className={classes.overlay2}></div>
                     <div className={classes.text}><span>Automation Powered by Cloud... <br/><span style={{cursor:'pointer', textDecoration:'underline'}} onClick={e=>window.open('https://iwcloudserver.ml', "_blank")}>Learn More</span></span></div>
                </div>
                <div className={classes.card}>
                    <div class={classes.bg3}></div>
                     <div className={classes.overlay3}></div>
                     <div className={classes.text}>Transform using AI... <br/>Learn More</div>
                </div>
             
            </div>
        </div>
       </div>
    )
}
