import React from 'react';
import { createUseStyles } from 'react-jss'
import Navbar from '../../Components/Navbar';
import HeroImage from './HeroImage'

import Footer from '../../Home/Footer';
import EulaData from './EulaData';


const useStyles = createUseStyles({
    root: {

    },

})

export default function EulaIVision() {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Navbar />
            <div style={{ paddingTop: '55px' }}>
                <HeroImage />
                <EulaData />
            </div>
            <Footer />
        </div>
    )
}
