import React from 'react';
import {createUseStyles} from 'react-jss'
import theme from '../theme';

const useStyles = createUseStyles({
    root:{
        textAlign:'center',
        margin:'80px 16px 60px 16px'
    },
    knowMoreBtn:{
        background:theme.success,
        color:'white',
        padding:'8px 20px',
        borderRadius:'50px',
        border:'none',
        outline:'none',
        marginLeft:'32px',
        cursor:'pointer'
    }
  })

export default function KnowMore() {
    const classes = useStyles()
    function contactUs(){
        window.location.href="/contact_us"
    }

    return (
        <div className={classes.root}>
            <h2 style={{display:'inline'}}>Want to know about our services?</h2> 
            <button className={classes.knowMoreBtn} onClick={contactUs}>CONTACT US</button>
        </div>
    )
}
