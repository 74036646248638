import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import Navbar from "../Components/Navbar";
import Footer from "../Home/Footer";
import TransformAndInnovate from "./TransformAndInnovate";

const useStyles = createUseStyles({
  root: {},
  bannerImage: {
 
  },

 
  "@media (max-width: 600px)": {
  
  },

  "@media (min-width: 1700px)": {
   
  },
});

export default function Simcase_product_info() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Navbar />

      <video width="100%" height="100%" controls style={{
        marginTop:'55px'
      }}>
        <source src="/videos/simcase_gen.mp4" type="video/mp4"/>
     
      </video>
    
      <Footer/>

     
    </div>
  );
}
