import React from 'react';
import {createUseStyles} from 'react-jss';
import "../overrides.css";
import theme from '../theme';


const useStyles = createUseStyles({
    root: {
        marginTop:'42px',
        marginBottom:'42px'
    },
    container:{
        maxWidth:'1280px',
        margin:'0 auto',
        display:'grid',
        gridTemplateColumns:'auto auto auto auto',
        gridGap:'32px'
    },
    card:{
        boxSizing:'border-box',
        padding:'16px',
        '& img':{
            width:'48px',
            marginBottom:'16px'
        },
        '& p':{
            marginTop:'8px',
            fontSize:'0.9rem',
            color:'grey'
        }
    },

    '@media screen and (max-width: 1024px)': {
        container:{
            gridTemplateColumns:'auto auto',

        },
        card:{
            textAlign:'center'
        }
    },

    '@media screen and (max-width: 600px)': {
        container:{
            gridTemplateColumns:'auto',

        },
        card:{
            textAlign:'center'
        }
    },
})

export default function OurApproach() {
    const classes = useStyles()

    const data = [
        {
            image:'/images/product_development/product_discovery.svg',
            title:'Product Discovery',
            description:'We help you with idea validation, product strategy, and how you can differentiate yourself in the market.'
        },
        {
            image:'/images/product_development/experience_design.svg',
            title:'Experience Design',
            description:'We help you build compelling user-interfaces that solve your business problems.'
        },
        {
            image:'/images/product_development/product_engineering.svg',
            title:'Product Engineering',
            description:'Need a rockstar product team? Be it owning the entire development or augmenting your in-house capabilities, we can help.'
        },
        {
            image:'/images/product_development/mvp_launch.svg',
            title:'MVP Launch',
            description:'If you have an idea on a napkin, we can help you quickly get it to the market with an MVP.'
        },
    ]

    return (
        <div className={classes.root}>
            <center>
                <h1 style={{marginBottom:'16px'}}>Our Approach</h1>
            </center>
            <div className={classes.container}>
                {
                    data.map((item, index)=>(
                        <div className={classes.card}>
                    <img src={item.image}/>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                </div>
                    ))
                }
            </div>
        </div>
    )
}
 