import React, { Component, createRef, useEffect, useRef, useState } from 'react';
import { Player } from 'video-react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import 'video-react/dist/video-react.css';
import BigPlayButton from 'video-react/lib/components/BigPlayButton';
import { play } from 'video-react/lib/actions/player';

function HeroImage() {
    let player = useRef()

    // setInterval(()=>{
    //   console.log(playVid)
    //   playVid.actions.play()
    // },5000)
    useEffect(()=>{
      console.log(player)
      // player.current.subscribeToStateChange(player);

      player.current.actions.play()
    },[])
 
    return (
      <div >
        <Player
        ref={player}
            fluid={false}
          height={500}
          width={"100%"}
          videoId="video-1"
          // autoPlay={true}
          muted={true}
          // playing={true}
        >
                {/* <BigPlayButton position="center" /> */}

          <source src="/videos/innowise.mp4" />
        </Player>
        {/* <button onClick={e=>player.current.actions.play()}>Play</button> */}
       
      </div>
    );
  
}

export default HeroImage;