import React from 'react';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    root: {
        marginTop:'28px',
        width:'100%',
        height:'50vh',
        position:'relative',
        background:"url('/images/about_us/journey.png')",
        backgroundSize:'cover',
        zIndex:-2,
        "& h1":{
            paddingTop:'16px',
            color:'white'
        }
    },

    background:{
        position:'absolute',
        width:'100%',
        height:'100%',
        background:'rgba(0,0,0,.5)',
        zIndex:-2
    },
   

    '@media screen and (max-width: 1024px)': {

    },

    '@media screen and (max-width: 600px)': {

    },
})

export default function OurJourney() {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.background}/>
            <div>
                <center>
                <h1>Our Journey</h1>
                </center>
            </div>
         
        </div>
    )
}
 