import React from 'react';
import {createUseStyles} from 'react-jss';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "../overrides.css";
import theme from '../theme';
import Navbar from '../Components/Navbar';
import HeroSection from './HeroSection';
import OurCommitments from './OurCommitments';
import ProductDevServices from './ProductDevServices';
import OurExpertise from './OurExpertise';
import ExperienceOurProducts from './ExperienceOurProducts';
import OurApproach from './OurApproach';
import Discuss from './Discuss';
import Footer from '../Home/Footer'


const useStyles = createUseStyles({
    root: {

    },

    '@media screen and (max-width: 1024px)': {

    },

    '@media screen and (max-width: 600px)': {

    },
})

export default function ProductDevelopment() {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Navbar/>
            <div style={{paddingTop:'55px'}}>
                <HeroSection/>
                <OurCommitments/>
                <ProductDevServices/>
                <OurExpertise/>
                <ExperienceOurProducts/>
                <OurApproach/>
                <Discuss/>
                <Footer/>
            </div>
         
        </div>
    )
}
 