import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss'
import Divider from '../Components/Divider';
import CaseDetails from '../Resources/CaseDetails';
import theme from '../theme';

const useStyles = createUseStyles({
    root: {
        marginTop: "40px",
        marginBottom: '40px',
        // margin :'40px auto'
    },
    images: {
        marginTop: '50px',
        paddingBottom: '40px',
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        // marginLeft:'100px',
        // marginRight:'100px',
        placeItems: 'center',
        maxWidth: '1150px',
        gridGap: '30px'
    },
    imgCard: {
        position: 'relative',
        width: 'fit-content',
        height: 'calc(100% - 7px)',
        overflow: 'hidden',
        cursor: 'pointer'
    },
    card1: {
        transition: '1s ease all',
        width: "130px",
        height: 0,
        borderBottom: '336px solid rgba(140,46,44,.8)',
        borderRight: '100px solid transparent',
        // '&:after':{
        //     content:'"Transform"',
        //     position:'absolute',
        //     top:0,
        //     "&:hover":{
        //         color:'white'
        //     }
        // }, 
        "& div": {
            color: 'white',
            position: 'absolute',
            maxWidth: "150px",
            top: '130px',
            left: '16px'
        },
        "&:hover": {
            width: "100%"
        }
    },

    card2: {
        transition: '1s ease all',
        width: "130px",
        height: 0,
        borderBottom: '336px solid rgba(14,25,55,.8)',
        borderRight: '100px solid transparent',
        // '&:after':{
        //     content:'"Transform"',
        //     position:'absolute',
        //     top:0,
        //     "&:hover":{
        //         color:'white'
        //     }
        // }, 
        "& div": {
            color: 'white',
            position: 'absolute',
            maxWidth: "150px",
            top: '130px',
            left: '16px'
        },
        "&:hover": {
            width: "100%"
        }
    },

    card3: {
        transition: '1s ease all',
        width: "130px",
        height: 0,
        borderBottom: '336px solid rgba(0,0,0,.8)',
        borderRight: '100px solid transparent',
        // '&:after':{
        //     content:'"Transform"',
        //     position:'absolute',
        //     top:0,
        //     "&:hover":{
        //         color:'white'
        //     }
        // }, 
        "& div": {
            color: 'white',
            position: 'absolute',
            maxWidth: "150px",
            top: '130px',
            left: '16px'
        },
        "&:hover": {
            width: "100%"
        }
    },

    '@media screen and (max-width: 1024px)': {
        images: {
            gridTemplateColumns: 'auto auto',
            maxWidth: '770px'

        }
    },

    '@media screen and (max-width: 600px)': {
        images: {
            gridTemplateColumns: 'auto',
        }
    },






})

export default function CustomerStories() {
    const classes = useStyles()

    const [data, setData] = useState([])
    useEffect(async () => {
        await fetch('dataUseCases.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                console.log(myJson)
                for (let useCaseIdx = 0; useCaseIdx < myJson.useCases.length; useCaseIdx++) {
                    const element = myJson.useCases[useCaseIdx];
                    if (Object.keys(element)[0] == "SALESFORCE" && useCaseIdx < 3) {
                        for (let index = 0; index < element[Object.keys(element)[0]].length; index++) {
                            const salesForceCases = element[Object.keys(element)[0]][index];
                            if(index <3)
                            setData(data => [...data, salesForceCases])
                        }
                    }

                }
            });
    }, [])

    // const data ={
    //      card1:{
    //         case_title: "Salesman",
    //         subtitle:"AI based recommendation engine",
    //         image:"/images/salesforce/story1.png"
    //     },   
    //      card2:{
    // case_title: "Fasttrac",
    //         subtitle:"NLP based customer service plugin",
    //         image:"/images/salesforce/story2.png"
    //     },   
    //      card3:{
    //         case_title: "Sales and Automation",
    //         subtitle:"",
    // image:"/images/salesforce/story3.png"
    //     },   
    // }
    const [caseData, setCaseData] = React.useState({ benefits: [] })
    const [showDetails, setShowDetails] = React.useState(false)

    const handleCaseClick=(e, caseNumber, data)=> {
        setShowDetails(true)
        setCaseData(data)
    }




    return (
        <div className={classes.root} id="coe">
            <CaseDetails open={showDetails} setOpen={setShowDetails} caseData={caseData} />

            <center>
                <h1 style={{}}>Customer Stories</h1>
                <Divider marginTop={'32px'} marginTop={'32px'} />

                <div className={classes.images}>
                    {data.length > 0 && data.map((useCase, idx) => (
                        // console.log(useCase)
                        <div key={idx} className={classes.imgCard} onClick={e => handleCaseClick(e, parseInt(idx + 1), useCase)}>
                            <img src={
                                 parseInt(idx + 1) % 3 == 1 ? "/images/salesforce/story1.png" :
                                 parseInt(idx + 1) % 3 == 2 ? "/images/salesforce/story2.png" :
                                parseInt(idx + 1) % 3 == 0 ? "/images/salesforce/story3.png" : null
                            } style={{ height: '300px' }}></img>

                            <div style={{ position: 'absolute', top: 0, left: 0, height: "100%", width: '100%', textAlign: 'left', overflow: 'hidden' }}>
                                <div className={
                                     parseInt(idx + 1) % 3 == 1 ? classes.card1 :
                                     parseInt(idx + 1) % 3 == 2 ? classes.card2 :
                                    parseInt(idx + 1) % 3 == 0 ? classes.card3 : null
                                } >
                                    <div>
                                        {useCase.article_title}
                                        {/* <p>{useCase.subtitle}</p> */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    ))}

                 

                </div>
            </center>
        </div>
    )
}
