import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import Navbar from "../Components/Navbar";
import Footer from "../Home/Footer";
import TransformAndInnovate from "./TransformAndInnovate";

const useStyles = createUseStyles({
  root: {},
  bannerImage: {
    height: "400px",
    width: "100%",
  },

  tab: {
    overflow: "hidden",
    border: "1px solid #ccc",
    backgroundColor: "#f1f1f1",
    textAlign: "center",

    button: {
      backgroundColor: "inherit",
      float: "left",
      border: "none",
      outline: "none",
      cursor: "pointer",
      padding: "14px 16px",
      transition: "0.3s",

      "&:hover": {
        backgroundColor: "#ddd",
      },

      "&:active": {
        backgroundColor: "#d04146",
      },
    },
  },
  tablinks: {
    height: "48px",
  },
  tabcontents: {
    display: "none",
    // padding: "6px 12px",
    // border: "1px solid #ccc",
    // borderTop: "none",
  },

  selectedTabContents: {
    display: "none",
    padding: "6px 12px",
    border: "1px solid #ccc",
    borderTop: "none",
  },

  selectedButton: {
    backgroundColor: "#d04146",
    border: "none",
    height: "65px",
    color: "white",
    fontSize: "15px",
    fontWeight: "bold",
    width: "315px",
    "&:focus": {
      outline: "none",
    },
  },

  notSelectedButton: {
    border: "none",
    border: "none",
    height: "65px",
    fontSize: "15px",
    fontWeight: "bold",
    width: "315px",
    "&:focus": {
      outline: "none",
    },
  },

  selectedTabStyles: {},

  notSelectedTabStyles: {
    display: "none",
  },

  "@media (max-width: 600px)": {
    bannerImage: {
      objectFit: "contain",
      height: "244px",
      width: "100%",
    },
  },

  "@media (min-width: 1700px)": {
    bannerImage: {
      height: "400px",
      width: "100%",
    },
  },
});

export default function Resources() {
  const classes = useStyles();

  const [AllUseCases, setAllUseCases] = useState([]);
  useEffect(() => {
    fetch("dataUseCases.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        console.log(myJson);
        setAllUseCases(myJson.useCases);
        // setData({TransformAndInnovate: myJson.TransformAndInnovate})
      });

    console.log(AllUseCases);
  }, []);

  const [selectedTab, setSelectedTab] = useState(0);

  const renderSwitch = (selectedTab) => {
    switch (selectedTab) {
      case 0:
        return (
          <>
            <TransformAndInnovate useCase={AllUseCases[0]} useCaseIndex={0} />
          </>
        );

      case 1:
        return (
          <>
            <TransformAndInnovate useCase={AllUseCases[1]} useCaseIndex={1} />
          </>
        );

      case 2:
        return (
          <>
            <TransformAndInnovate useCase={AllUseCases[2]} useCaseIndex={2} />
          </>
        );

      case 3:
        return (
          <>
            <TransformAndInnovate useCase={AllUseCases[3]} useCaseIndex={3} />
          </>
        );
    }
  };

  return (
    <div className={classes.root}>
      <Navbar />

      {/* case study banner will go here */}
      <div>
        <img
          className={classes.bannerImage}
          src="/images/resource-banner.png"
        />
      </div>

      {/** html tabbar will go here */}
      <div style={{ textAlign: "center", border: "none", marginTop: "30px" }}>
        <div style={{ display: "inline-block", border: "none" }}>
          <button
            className={
              selectedTab === 0
                ? classes.selectedButton
                : classes.notSelectedButton
            }
            onClick={() => setSelectedTab(0)}
          >
            Robotic Process Automation
          </button>
        </div>

        <div style={{ display: "inline-block", border: "none" }}>
          <button
            className={
              selectedTab === 1
                ? classes.selectedButton
                : classes.notSelectedButton
            }
            onClick={() => setSelectedTab(1)}
          >
            Data Science and Machine Learning
          </button>
        </div>

        <div style={{ display: "inline-block", border: "none" }}>
          <button
            className={
              selectedTab === 2
                ? classes.selectedButton
                : classes.notSelectedButton
            }
            onClick={() => setSelectedTab(2)}
          >
            Salesforce
          </button>
        </div>

        <div style={{ display: "inline-block", border: "none" }}>
          <button
            className={
              selectedTab === 3
                ? classes.selectedButton
                : classes.notSelectedButton
            }
            onClick={() => setSelectedTab(3)}
          >
            Cloud and Devops
          </button>
        </div>
      </div>

      <div style={{ paddingTop: "55px" }}>
        {/* {renderSwitch(selectedTab)} */}
        {AllUseCases.length > 0 ? renderSwitch(selectedTab) : null}
        {/* <HeroImage/> */}
        {/* {AllUseCases.map((useCase, useCaseIndex) => (
          <TransformAndInnovate useCase={useCase} useCaseIndex={useCaseIndex} />
        ))} */}

        <Footer />
      </div>
    </div>
  );
}
