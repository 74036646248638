import React from 'react';
import {createUseStyles} from 'react-jss';
import "../overrides.css";
import theme from '../theme';




const useStyles = createUseStyles({
    root:{
        marginTop:'20px',
        background:'#EAEBF1',        

    },
    main: {
        boxSizing:'border-box',
        padding:'16px',
        margin:'0 auto',
        maxWidth:'1280px',
        display:'grid',
        gridTemplateColumns:'1fr 1fr'
    },
    left:{
        display:'grid',
        alignContent:"center",
        boxSizing:'border-box',
        padding:'32px',
        
        '& .list':{
            marginTop:'24px',
        
            '& ul':{
                color:'grey',
                marginLeft:'20px',
                lineHeight:'40px'
            }
        }
    },
    right:{
        display:'flex',
        paddingTop:'16px',
        paddingBottom:'16px',
    },
  

    '@media screen and (max-width: 766px)': {
        main:{
            gridTemplateColumns:'auto',
        },
        left:{
            padding : '32px 16px'
        },
        right:{
            display:'none',
        }
    },

    '@media screen and (max-width: 600px)': { 

    },
})

export default function TestYourLimits() {
    const classes = useStyles()

    return (
        <div className={classes.root}>
           <div className={classes.main}>
           <div className={classes.left}>
                <div>
                    <h1>Test Your Limits,</h1>
                    <h1>Do Your Best Work</h1>
                    <div style={{borderBottom:'4px solid '+theme.iwRed, width:"50px", marginTop:'24px', marginBottom:'0px'}}/>
                </div>
                <div className="list">
                    <ul>
                      
                        <li>Empowered to work independently</li>

                        <li>Learn and grow, you work with clients directly.</li>

                        <li>Work on latest, next gen technologies</li>

                        <li>Research and build cool products </li>

                   
                    </ul>
                </div>
            </div>
            <div className={classes.right}>
                <img src={'/images/careers/test_limit.png'} style={{width:'70%', margin:'0 auto', height:'intrinsic'}}/>
            </div>
           </div>
        </div>
    )
}
 