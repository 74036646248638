import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import React from 'react'
import { createUseStyles } from 'react-jss';
import theme from '../theme';
import Divider from '../Components/Divider';

const useStyles = createUseStyles({
  root: {
    // maxWidth:'1280px',
    // textAlign:'center'
    display: 'grid',
    placeItems: 'center',
    // maxWidth:'1280px',
    margin: '0 auto'
  },

})

export default function PartnersCarousel() {
  const classes = useStyles()
  // alert(window.innerWidth)

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  return (
    <div
      style={{
        background: theme.background,
        paddingTop: '40px',
        paddingBottom: '50px'

      }}>
      <center>
        <h1 style={{ fontSize: '2.5em' }}>Our Partnerships</h1>
        <Divider marginTop={'28px'} marginBottom={'28px'} />

      </center>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        // ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5s"
        transitionDuration={2000}
        containerClass={classes.root}
        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        //   deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        <div style={{ textAlign: 'center' }}><img src="/images/partners/UiPath_Diamond.png" style={{ width: '250px' }} /></div>
        <div style={{ textAlign: 'center' }}><img src="/images/partners/UiPath_USN.png" style={{ width: '250px' }} /></div>
        {/* <div  style={{textAlign:'center'}}><img src="/images/partners/aa_small.png"  style={{width:'250px'}}/></div> */}
        <div style={{ textAlign: 'center' }}><img src="/images/partners/abby_small.png" style={{ width: '250px' }} /></div>
        <div style={{ textAlign: 'center' }}><img src="/images/partners/aws_small.png" style={{ width: '250px' }} /></div>
        {/* <div><img src="/images/tni/5.png" /></div>
                <div><img src="/images/tni/6.png" /></div> */}


      </Carousel>
    </div>
  )
}
