import React from 'react';
import {createUseStyles} from 'react-jss';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "../overrides.css";
import theme from '../theme';
import Navbar from '../Components/Navbar';


const useStyles = createUseStyles({
    root:{

    },

    background: {
        background:'url("/images/cloud_devops/hero_img.png")',
        backgroundSize:'cover',
        backgroundPosition:'center',
        // background:'red',
        height:'540px',
        width:'100%'
    },

    overlay:{
        height:'100%',
        width:'100%',
        background:'rgba(0, 0, 0, 0.6)',
        display:'flex',
        alignItems:'center'
    },

    content:{
        // border:'1px solid black',
        marginLeft:'32px',
        color:'white',
        maxWidth:'1024px',
        
        '& h1':{
            lineHeight:'68px',

            fontSize:'3.5rem',
            // marginBottom:'16px'
        },
        '& p':{
            fontSize:'1.1rem'
        },
        '& button':{
            outline:'none',
            border:'none',
            background:`linear-gradient(to right, ${theme.primaryColor} 50%, ${theme.iwRed} 50%)`,
            backgroundSize: '200% 100%',
            backgroundPosition:'right bottom',
            color:'white',
            padding:'8px 20px',
            borderRadius:'50px',
            marginTop:'22px',
            cursor:'pointer',
            transition:'all 0.4s ease-in-out'
            
        },
        '& button:hover':{
            backgroundPosition:'left bottom'
        }
    },

    '@media screen and (min-width: 1366px)': {
        content:{
            '& h1':{
                lineHeight:'72px',
    
                // fontSize:'3.5rem',
                // marginBottom:'16px'
            },
        }

    },
    

    '@media screen and (max-width: 1024px)': {

    },


    '@media screen and (max-width: 600px)': {

        background:{
            height:'calc(100vh - 45px)'
        },
        content:{
            marginRight:'16px',
            // border:'1px solid black',
            marginLeft:'16px',
            paddingRight:'16px',
            color:'white',
            // maxWidth:'1024px',
            
            '& h1':{
                fontSize:'2.5rem',
                lineHeight:'50px'
                // marginBottom:'16px'
            },
            '& p':{
                marginTop:'16px',
                fontSize:'1.1rem'
            },
            '& button':{
                outline:'none',
                border:'none',
                background:theme.iwRed,
                color:'white',
                padding:'8px 20px',
                borderRadius:'50px',
                marginTop:'22px',
                cursor:'pointer',
                
            },
            '& button:hover':{
                background:theme.primaryColor
            }
        }
    },
    // '@media screen and (-height: 640px)': {

    // },
})

export default function HeroSection() {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.background}>
                <div className={classes.overlay}>
                    <div className={classes.content}>
                        <h1>
                        Let's see together the power of
cloud and DevOps in business.</h1>
                        <p>
                        InnoWise helps companies build and scale faster better leaving the manual work
behind and embrace an automated collaborative and agile work environment.
                        </p>
                        <button>CONTACT US</button>
                    </div>
                </div>

            </div>
        </div>
    )
}
 