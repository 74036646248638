import React from 'react';
import {createUseStyles} from 'react-jss'
import Navbar from '../Components/Navbar';
import HeroImage from './HeroImage'
import KnowMore from './KnowMore';
import OurOfferings from './OurOfferings';

import PartnersCarousel from './PartnersCarousel'
import SecondSection from './SecondSection'

import Footer from '../Home/Footer';
import InnoWiseRPASection from './InnoWiseRPASection';
import AutomationAsService from './AutomationAsService';
import UseCases from './UseCases';


const useStyles = createUseStyles({
    root:{

    },
 
  })

export default function RPA() {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Navbar/>
            <div style={{paddingTop:'55px'}}>
                <HeroImage/>
                <SecondSection/>
                <OurOfferings/>
                <KnowMore/>
                <PartnersCarousel/>
                <InnoWiseRPASection/>
                <AutomationAsService/>
                <UseCases/>
            </div>
            <Footer/>
        </div>
    )
}
