import React from 'react';
import {createUseStyles} from 'react-jss'
import theme from '../theme';

const useStyles = createUseStyles({
    root:{
        marginTop:'-8px',
        paddingTop:'80px',
        paddingBottom:'80px',
        background:'white'
    },
    heading:{
        textAlign:'center',
        margin:"20px",
    },
    OfferingsInfo:{
        display:'flex',
        alignItems:'center',
        // justifyContent:"space-between",
        paddingLeft:'100px',
        paddingRight:'100px'
    },
    offeringInfoLeft:{
        minWidth:'450px',
        marginRight:'48px'
    },

    verticalDivider:{
        position:'absolute', 
        height:'100%', 
        left:0 , top:0, 
        borderRight:"3px solid "+theme.error
    },
    learnMoreBtn:{
        background:'black',
        color:'white',
        padding:'8px 20px',
        borderRadius:'50px',
        border:'none',
        outline:'none',
        transform:'translatey(35px)',
        cursor:'pointer'
    },

    '@media screen and (max-width: 1024px)': {
    
        root:{
            display:'none'
        },

    
      },

    '@media screen and (max-width: 600px)': {

        root:{
            display:'none'
        },

    
    
      },

  })

export default function DrivingDigitalTransofmation() {
    const classes = useStyles()

    function handleLearnMore(){
        document.getElementById('coe').scrollIntoView()
    }

    return (
        <div className={classes.root} id="ourOfferings">
            {/* <div className={classes.heading}>
                <h1 style={{fontSize:'2.5em'}}>Our Offerings</h1>
            </div> */}
            <div className={classes.OfferingsInfo}>
                <h1 className={classes.offeringInfoLeft}>Driving Digital Transformation for Fortune companies Globally</h1>
                <div style={{height:'100px', borderRight:'3px solid '+theme.primaryColor, marginRight:'48px'}}></div>
                <div>
                    <p>
                    Digital transformation is no longer nice to have, but a must have for any organization that wants to survive and thrive. But digital transformation means many things to many people and often it’s difficult to get started with transformation or have a clear strategy. We at InnoWise make is simple and easy to get started with your digital journey by focusing on transforming building blocks (processes) and drive tangible value creation in agile manner. Digital transformation does not have to be one monolith vision/initiative, but can be smaller, faster value added initiatives aligned with corporate business goals. With InnoWise’s focus on Automation, Data Science and Cloud, we are helping some of the largest corporations digitize their processes and become more efficient, innovative and compliant organizations.

                    </p>
                </div>
            </div>

           

            
        </div>
    )
}
