import React from 'react';
import {createUseStyles} from 'react-jss';
import Divider from '../Components/Divider';
import "../overrides.css";
import theme from '../theme';



const useStyles = createUseStyles({
    root: {
        marginTop:'32px',
        marginBottom:'60px',
    },
    heading:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center'
    },
    content:{
        // border:'1px solid red'
        marginTop:'32px'
    },
    table:{
        maxWidth:'1280px',
        margin:'0 auto',
        border:'1px solid '+theme.iwRed,

        

    },
    row:{
        boxSizing:'border-box',
        padding:'16px',
        display:'grid',
        gridTemplateColumns:'200px auto',
        border:'1px solid '+theme.iwRed,
        '& .title':{
            // width:'200px',
            display:'flex',
            alignItems:'center',
            fontWeight:600
            // background:'red'
        },
        '& .description':{
            textAlign:'left'
        }
    },

    '@media screen and (max-width: 1280px)': {
        table:{
            maxWidth:'calc(100% - 32px)'
        }
    },

    '@media screen and (max-width: 600px)': {
        row:{
            gridTemplateColumns:'auto',
            '& .title':{
                marginBottom:'8px'
            }
        },
      
    },
})


export default function OurExpertise() {
    const classes = useStyles()

    const data = [
        {
            title:'METHODOLOGIES',
            description:'Agile • Lean • Waterfall • Prototype • Spiral • Scrum • Scale Agile • Kanban • RAD'
        },
        {
            title:'CAPABILITIES',
            description:'Artificial Intelligence • BlockChain • Data Analytics • Business Intelligence • RPA • Product Prototyping • Web Development • Mobile App Developent • Enterprise Solutions'
        },
        {
            title:'DATABASE',
            description:'MongoDB • MySQL'
        },
        {
            title:'SERVERS',
            description:'Amazon Web Services • Google Cloud • Microsoft Azure'
        },
        {
            title:'INDUSTRIES',
            description:'Healthcare • FinTech • Manufacturing • Retail • eCommerce • Travel • Hospitality • Logistics • Startups • On Demand Apps'
        },

    ]

    return (
        <div className={classes.root}>
           <div className={classes.heading}>
            <h1>Our Expertise</h1>
      

            <h3>Transform and Innovate with InnoWise</h3>
           </div>
           
           <div className={classes.content}>
                <div className={classes.table}>
                    {
                        data.map((item,index)=>(
                            <div className={classes.row}>
                                <p className='title'>{item.title}</p>
                                <p className='description'>{item.description}</p>
                            </div>
                        ))
                    }
                </div>
           </div>
         
        </div>
    )
}
 