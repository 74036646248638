import React from 'react';
import {createUseStyles} from 'react-jss';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Divider from '../Components/Divider';
import "../overrides.css";
import theme from '../theme';



const useStyles = createUseStyles({
    root: {
        background:'#E2E2E2',
        paddingTop:'32px',
        paddingBottom:'24px'
    },
    productDevCarousel:{
        // margiTop:'100px',
        display:'grid',
        gridTemplateColumns:'1fr 1fr',
        maxWidth:'1024px',
        margin:'45px auto 0 auto',
        gridGap:'50px'

    },
    card:{
        fontSize:'.9rem',
        // lineHeight:'2rem',
        boxSizing:'border-box',
        margin:'16px',
        maxWidth:'480px',
        textAlign:'center',
        '& img':{
            width:'56px'
        },
        '& h3':{
            marginBottom:'16px'
        },
        '& p':{
            // textAlign:'justify'
        }
    },

    '@media screen and (max-width: 1024px)': {

    },

    '@media screen and (max-width: 600px)': {
        productDevCarousel:{
            gridTemplateColumns:'auto',
            maxWidth:'100%',
    
        },
    },
})

export default function ProductDevServices() {
    const classes = useStyles()

    const data = [
        {
            image:'/images/product_development/product_development.svg',
            heading:'Product Development',
            description:'We will analyze your business operations and develop tailor made products and solutions that can meet your specific industry requirements.'
        },
        {
            image:'/images/product_development/web_development.svg',
            heading:'Web Development',
            description:'Our experts handle all aspects of web development, from conceptualization to design and development of responsive website and web applications.'
        },
        {
            image:'/images/product_development/front_end_development.svg',
            heading:'Front End Development',
            description:'We have unrivalled expertise in full-stack front-end development and our experts can develop interactive web and mobility solutions for enhanced customer experiences.'
        },
        {
            image:'/images/product_development/ui_ux_design.svg',
            heading:'UI/UX Design',
            description:'We pride ourselves on creating engaging and unique user interfaces that are easy to use, intuitive and adaptive.'
        },
        {
            image:'/images/product_development/erp_software_development.svg',
            heading:'ERP Software Development',
            description:'We can develop ERP software solutions that can integrate back office business processes and facilitate the information flow within your organization for better decision making.'
        },
        {
            image:'/images/product_development/custom_software_development.svg',
            heading:'Custom Software Development',
            description:'Each business has unique needs and requirements. We develop customized solutions that can perfectly fit your business operations and process flow.'
        },
    ]

    return (
        <div className={classes.root}>
           <center>
               <h1>Our Product Development Services</h1>
               <Divider marginBottom={'32px'} marginTop={'32px'}/>
           </center>
           <div className={classes.productDevCarousel}>
               {
                   data.map((item,index)=>(
                    <div className={classes.card}>
                        <img src={item.image}/>
                        <h3>{item.heading}</h3>
                        <p>{item.description}</p>
                    </div>
                   ))
               }
           </div>

         
        </div>
    )
}
 