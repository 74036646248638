import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss'
import Divider from '../Components/Divider';
import theme from '../theme';
// import data from '../data';
import CaseDetails from './CaseDetails';

const useStyles = createUseStyles({
    root: {
        marginTop: "40px",
        marginBottom: '40px',
        // margin :'40px auto'
    },
    images: {
        marginTop: '50px',
        paddingBottom: '40px',
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        // marginLeft:'100px',
        // marginRight:'100px',
        placeItems: 'center',
        maxWidth: '1150px',
        gridGap: '30px'
    },
    imgCard: {
        position: 'relative',
        width: 'fit-content',
        height: 'calc(100% - 7px)',
        overflow: 'hidden',
        cursor: 'pointer'
    },
    card1: {
        transition: '1s ease all',
        width: "130px",
        height: 0,
        borderBottom: '336px solid rgba(140,46,44,.8)',
        borderRight: '100px solid transparent',
        // '&:after':{
        //     content:'"Transform"',
        //     position:'absolute',
        //     top:0,
        //     "&:hover":{
        //         color:'white'
        //     }
        // }, 
        "& div": {
            color: 'white',
            position: 'absolute',
            maxWidth: "150px",
            top: '130px',
            left: '16px'
        },
        "&:hover": {
            width: "100%"
        }
    },

    card2: {
        transition: '1s ease all',
        width: "130px",
        height: 0,
        borderBottom: '336px solid rgba(14,25,55,.8)',
        borderRight: '100px solid transparent',
        // '&:after':{
        //     content:'"Transform"',
        //     position:'absolute',
        //     top:0,
        //     "&:hover":{
        //         color:'white'
        //     }
        // }, 
        "& div": {
            color: 'white',
            position: 'absolute',
            maxWidth: "150px",
            top: '130px',
            left: '16px'
        },
        "&:hover": {
            width: "100%"
        }
    },

    card3: {
        transition: '1s ease all',
        width: "130px",
        height: 0,
        borderBottom: '336px solid rgba(0,0,0,.8)',
        borderRight: '100px solid transparent',
        // '&:after':{
        //     content:'"Transform"',
        //     position:'absolute',
        //     top:0,
        //     "&:hover":{
        //         color:'white'
        //     }
        // }, 
        "& div": {
            color: 'white',
            position: 'absolute',
            maxWidth: "150px",
            top: '130px',
            left: '16px'
        },
        "&:hover": {
            width: "100%"
        }
    },

    '@media screen and (max-width: 1024px)': {
        images: {
            gridTemplateColumns: 'auto auto',
            maxWidth: '770px'

        }
    },

    '@media screen and (max-width: 600px)': {
        images: {
            gridTemplateColumns: 'auto',
        }
    },






})

export default function TransformAndInnovate({ useCase, useCaseIndex }) {
    const classes = useStyles()
    
    const [showDetails, setShowDetails] = React.useState(false)
    const [caseData, setCaseData] = React.useState({ benefits: [] })



    const handleCaseClick = (e, caseNumber, data) => {
        setShowDetails(true)
        setCaseData(data)
    }



    return (
        <div className={classes.root} id="coe">
            <CaseDetails open={showDetails} setOpen={setShowDetails} caseData={caseData} />

            <center>
                <h1 style={{ fontSize: '2.5em' }}>{
                     Object.keys(useCase)[0] == 'RPA' ? 'Robotic Process Automation' :
                    Object.keys(useCase)[0] == 'DS_ML' ? 'Data Science & Machine Learning' :
                    Object.keys(useCase)[0] == 'SALESFORCE' ? 'Salesforce' :

                        Object.keys(useCase)[0] == 'CLOUD_DEVOPS' ? 'Cloud Devops' :
                            Object.keys(useCase)[0]}
                </h1>
                <Divider marginTop={'28px'} marginBottom={'28px'} />

                {/* <h3 style={{position:'relative'}}>Our Customer Stories</h3> */}
                <div className={classes.images}>
                    {useCase[Object.keys(useCase)[0]].map((data, idx) => (
                        <React.Fragment>
                            <div className={classes.imgCard} onClick={e => handleCaseClick(e, parseInt(idx + 1), data)}>
                                {/* <img src={'/images/tni/' + parseInt(idx + 1) + '.png'}></img>
                                 */}
                                 <img src={ parseInt(idx + 1) % 6 == 1 ? '/images/tni/' + parseInt(1) + '.png' :
                                            parseInt(idx + 1) % 6 == 2 ? '/images/tni/' + parseInt(2) + '.png' :
                                            parseInt(idx + 1) % 6 == 3 ? '/images/tni/' + parseInt(3) + '.png' :
                                            parseInt(idx + 1) % 6 == 4 ? '/images/tni/' + parseInt(4) + '.png' :
                                            parseInt(idx + 1) % 6 == 5 ? '/images/tni/' + parseInt(5) + '.png' :
                                            parseInt(idx + 1) % 6 == 0 ? '/images/tni/' + parseInt(6) + '.png' : 
                                            '/images/tni/' + parseInt(1) + '.png'}></img>

                                <div style={{ position: 'absolute', top: 0, left: 0, height: "100%", width: '100%', textAlign: 'left', overflow: 'hidden' }}>
                                    <div className={
                                        parseInt(idx + 1) % 3 == 1 ? classes.card1 :
                                            parseInt(idx + 1) % 3 == 2 ? classes.card2 :
                                                parseInt(idx + 1) % 3 == 0 ? classes.card3 : null} >
                                        <div>
                                            <p>
                                            {data.article_title}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
           
                </div>
            </center>
        </div>
    )
}
