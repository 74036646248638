import React from "react";
import { createUseStyles } from "react-jss";
import theme from "../theme";

const useStyles = createUseStyles({
  root: {
    background: "#d9d9d9",
  },
  main: {
    display: "flex",
    justifyContent: "space-evenly",
    padding: "80px 40px",
  },
  contactUs: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    gridGap: "16px",
    "& div": {
      fontSize: "0.75rem",
    },
  },

  emailInput: {
    "&::placeholder": {
      color: 'white',
      opacity: 0.7
    }
  },

  subNewsletter: {
    maxWidth: "280px",
    "& p": {
      fontSize: ".75rem",
    },
    "& input": {
      width: "calc(100% - 16px)",
      marginTop: "8px",
      textAlign: "center",
      background: theme.primaryColor,
      border: "none",
      outline: "none",
      padding: "8px",
    },

    "& button": {
      color: "white",
      background: theme.success,
      outline: "none",
      border: "none",
      width: "calc(100%)",
      padding: "8px",
      marginTop: "8px",
      cursor: "pointer",
    },
  },
  bottom: {
    display: "flex",
    background: theme.primaryColor,
    color: "white",
    padding: "16px",
    fontSize: ".9rem",
  },
  footerLinks: {
    display: "flex",
    "& a": {
      color: "white",
      textDecoration: "none",
      marginLeft: "16px",
    },
  },
  socialMedia: {
    display: "flex",
    marginTop: "8px",
    "& img": {
      height: "32px",
      marginRight: "8px",
      cursor: "pointer",
    },
  },
  usefulLinks: {
    "& a": {
      textDecoration: "none",
      fontSize: "0.8rem",
      color: "black",
    },
  },
  "@media screen and (max-width: 1024px)": {
    main: {
      display: "flow-root",
    },
    footerLinks: {
      flexDirection: "column",
    },
    socialMedia: {
      display: "grid",
      //   boxSizing:'border-box',
      gridTemplateColumns: "40px 40px",
      "& img": {
        marginTop: "8px",
        marginRight: "8px",
      },
    },
  },
});

export default function Footer() {
  const classes = useStyles();
  const [email, setEmail] = React.useState(null);
  function handleOnSubmit(e) {
    e.preventDefault();
    console.log(email);
    var bod = {
      email: email,
    };
    e.target.reset();
    fetch("https://innowise.us/api/subscriptionRequest", {
      method: "post",
      body: JSON.stringify(bod),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log("Created Gist:", data.html_url);
        e.target.reset();
        setEmail(null);
      });
  }
  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div style={{}}>
          <h3 style={{ marginBottom: "8px" }}>Contact Us</h3>
          <div className={classes.contactUs}>
            <div>
              USA - HEADQUARTERS
              <br />
              3434, Duplin Creek Drive, Katy,
              <br />
              Houston. Texas 77494, USA
              <div className={classes.socialMedia}>
                <a
                  href="https://www.facebook.com/InnoWiseTechnologies/"
                  target="blank"
                >
                  <img src="/images/sm/fb.svg" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCSmuuZCDx10oA0HTzzFtxAA"
                  target="blank"
                >
                  <img src="/images/sm/youtube.svg" />
                </a>
                <a href="https://twitter.com/InnoWiseLLC" target="blank">
                  <img src="/images/sm/twitter.svg" />
                </a>
                <a
                  href="https://www.linkedin.com/company/innowisetechnologies/"
                  target="blank"
                >
                  <img src="/images/sm/linkedin.svg" />
                </a>
              </div>
            </div>
            <div>
              INDIA - DELIVERY CENTRE
              <br />
              C/O iLink Multitech Solutions Pvt. Ltd / iLink Digital,<br />5th Floor, Malpani Agile, PAN Card Club Road,<br />Baner, Pune, Maharashtra 411045
            </div>
            <div>
              SINGAPORE - REGIONAL OFFICE
              <br />
              150, Thomson Road, #14-01
              <br />
              Thomson Euro-Asia
              <br />
              Singapore(307605)
            </div>
          </div>
        </div>
        <div>
          <h3 style={{ marginBottom: "8px" }}>Useful Links</h3>
          <div className={classes.usefulLinks}>
            <a href="/about_us">About Us</a>
            <br />
            {/* <a href="">Terms & Conditions</a><br/>
                    <a href="">Privacy Policy</a><br/> */}
            <a href="/contact_us">Contact Us</a>
            <br />
            <a href="/careers">Careers</a>
            <br />
          </div>
        </div>
        <div className={classes.subNewsletter}>
          <form onSubmit={handleOnSubmit}>
            <h3 style={{ marginBottom: "8px" }}>Subscribe</h3>
            <p>
              Subscribe to our weekly newsletter to receive the lastest freebies
              in your inbox
            </p>
            <input
              placeholder="Your Email Address"
              type="email"
              style={{ color: "white" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={classes.emailInput}
            ></input>
            <br />
            <button type="submit">Subscribe</button>
          </form>
        </div>
      </div>
      <div className={classes.bottom}>
        <div style={{ flex: 1 }}>
          Copyright © 2021 InnoWise LLC. All rights reserved
        </div>
        <div className={classes.footerLinks}>
          <a href="">Privacy Policy</a>
          <a href="">Terms of Use</a>
          <a href="">Legal</a>
          <a href="">Site Map</a>
        </div>
      </div>
    </div>
  );
}
