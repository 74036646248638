import React from 'react';
import {createUseStyles} from 'react-jss'


const useStyles = createUseStyles({
    root:{
        background:`url("/images/about_us/about_us_bg.PNG")`,
        height:'500px',
        width:'100%',
        backgroundSize:'cover',
        backgroundRepeat:'no-repeat',
        backgroundPosition:'top center',
        marginBottom:'30px'
    },
    background:{
        height:'100%',
        width:'100%',
        background:'rgba(0, 62, 153,.7 )',
        display:'grid',
        placeItems:'center'
    },

 
  })

export default function TopSection() {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.background}>
                <div style={{color:'white'}}>
                    <center>
                    <h1>Contact Us</h1>
                    <h4>Home / Contact Us</h4>
                    </center>
                </div>
                

            </div>
          
        </div>
    )
}
