import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './Home';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs'
import ProductDevelopment from './ProductDevelopment'
import DSML from './DS_ML';
import Salesforce from './Salesforce'
import RPA from './RPA'
import Careers from './Careers';
import Resources from './Resources';
import Cloud_DevOps from './Cloud_DevOps';
import Upsell_cross_sell_recommendations_plugin_artefacts from './Resources/upsell_cross_sell_recommendations_plugin-artefacts'
import Simcase_product_info from './Resources/simcase_product_info'
import EulaIVision from './iVision/Eula/index';
// import 'bootstrap/dist/css/bootstrap.min.css';



ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/about_us" component={AboutUs} />
        <Route path="/contact_us" component={ContactUs} />
        <Route path="/product_development" component={ProductDevelopment} />
        <Route path="/ds_ml" component={DSML} />
        <Route path="/salesforce" component={Salesforce} />
        <Route path="/rpa" component={RPA} />
        <Route path="/careers" component={Careers} />
        <Route path="/resources/upsell_cross_sell_recommendations_plugin-artefacts" component={Upsell_cross_sell_recommendations_plugin_artefacts} />
        <Route path="/resources/simcase_product_info" component={Simcase_product_info} />

        <Route path="/resources" component={Resources} />

        <Route path="/cloud_devops" component={Cloud_DevOps} />
        <Route path="/ivision/eula" component={EulaIVision} />

      </Switch>
    </Router>

  </React.StrictMode>,
  document.getElementById('root')
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
